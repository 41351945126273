import { createNewSortInstance } from 'fast-sort';
import { Loader } from '../../components/Loader';
import { PRAC as PRACComponent } from '../../components/PRAC';
import { usePRACTestSites } from '../../hooks/usePRACTestSites';
import { useCredentialValidate } from '../../hooks/useCredentialValidate';
import { useEffect,  } from 'react';
import { customURLs, getToken } from '../../tools/utils';
import { useMsal } from '@azure/msal-react';
import { Link } from 'react-router-dom';

const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
});

export const TSCPRAC = () => {
    const {instance}=useMsal();
    const activeAccount=instance.getActiveAccount()
    const validateQuery = useCredentialValidate('TSC-Practical');
    const validateData = validateQuery.data?.data;
    const { data: eotData, isLoading: eotISLoading, error: eotError } = usePRACTestSites();
    const token = getToken()
    const eotSites = naturalSort(eotData?.data.Data || []).asc(site => site.TestSiteNumber);

    const pending = eotSites.filter(test => 'PendingReview' === test.Status);
    const approved = eotSites.filter(test => 'Approved' === test.Status);
    const expired = eotSites.filter(test => 'Expired' === test.Status);
    console.log(approved);
    useEffect(() => {
        if (validateData) {

            if (!validateData.Data) {
                window.location.href = '/testing-personnel/';
            }
        }
    }, [validateData]);

    return (
        <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
            <article className="react-container">
        <div className="cco_section cco_section_0">
            <div className="cco_row">
                <div className="cco_col">
                    <div className="cco_text cco_text_11">
                        <div className="cco_text_inner">
                            <strong>
                                <a href="/testing-personnel">Testing Personnel</a>
                            </strong>{' '}
                            {'>'} Practical Locations
                        </div>
                    </div>
                </div>
            </div>

            <div className="cco_row cco_row_0">
                        <div className="cco_col cco_col_12">
                            <div className="cco_text cco_text_1">
                                <div className="cco_text_inner">
                                    <h1>
                                         {activeAccount && activeAccount.idTokenClaims.name.toUpperCase()}
                                        <span className="divider" />
                                        <small>
                                            CCO ID <strong>{activeAccount && activeAccount.idTokenClaims?.extension_CandidateId as string}</strong>
                                        </small>
                                    </h1>
                                </div>
                            </div>
                        </div>
            </div>


    
                <>
                    <div className="cco_row cco_row_7">
                        <div className="cco_col cco_col_6">
                            <div className="cco_text cco_text_10">
                                <div className="cco_text_inner">
                                    <h2>My Practical Test Sites</h2>
                                </div>
                            </div>
                        </div>

                        <div className="cco_col cco_col_7">
                            <div className="cco_btn cco_btn_3">
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a
                                    href={`${token && customURLs?.manageMyTestAdministrationsUrl?.button_url
                                        ? customURLs.manageMyTestAdministrationsUrl.button_url +
                                        '?id_token=' +
                                        token
                                        : '#'
                                        }`}
                                    target="_blank"
                                >
                                    {customURLs?.manageMyTestAdministrationsUrl?.button_text ||
                                        'Button Text'}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="cco_row cco_row_3">
                        <div className="cco_col">

                            <ul className="cco_menu cco_menu_4">
                                <li className="cco_menu_item cco_menu_item_icon cco_menu_item_icon_plus">
                                    {/* <a href="/tsc-prac/new-prac">Add new practical test site</a> */}
                                    <Link to={'/tsc-prac/new-prac'} state={{single:false,eotId:null}}>Add new practical test site</Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {pending.length > 0 && (
                        <>
                            <div className="cco_row cco_row_7">
                                <div className="cco_col cco_col_6">
                                    <div className="cco_text cco_text_10">
                                        <div className="cco_text_inner">
                                            <h2>My Pending Review Practical Test Sites</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_3">
                                <div className="cco_col">
                                    {eotISLoading ? (
                                        <Loader />
                                    ) : eotError ? (
                                        <p>{eotError.message}</p>
                                    ) : pending.length < 1 ? (
                                        <p>No Practical Test Sites found</p>
                                    ) : (
                                        <ul className="cco_accordion">
                                            {pending.map(site => (
                                                <PRACComponent key={site.LocationId} item={site} type="PendingReview" />
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {approved.length > 0 && (
                        <>
                            <div className="cco_row cco_row_7">
                                <div className="cco_col cco_col_6">
                                    <div className="cco_text cco_text_10">
                                        <div className="cco_text_inner">
                                            <h2>My Approved Practical Test Sites</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_3">
                                <div className="cco_col">
                                    {eotISLoading ? (
                                        <Loader />
                                    ) : eotError ? (
                                        <p>{eotError.message}</p>
                                    ) : approved.length < 1 ? (
                                        <p>No Practical Test Sites found</p>
                                    ) : (
                                        <ul className="cco_accordion">
                                            {approved.map(site => (
                                                <PRACComponent key={site.LocationId}  item={site} type="Approved" />
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {expired.length > 0 && (
                        <>
                            <div className="cco_row cco_row_7">
                                <div className="cco_col cco_col_6">
                                    <div className="cco_text cco_text_10">
                                        <div className="cco_text_inner">
                                            <h2>My Expired Practical Test Sites</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="cco_row cco_row_3">
                                <div className="cco_col">
                                    {eotISLoading ? (
                                        <Loader />
                                    ) : eotError ? (
                                        <p>{eotError.message}</p>
                                    ) : expired.length < 1 ? (
                                        <p>No Practical Test Sites found</p>
                                    ) : (
                                        <ul className="cco_accordion">
                                            {expired.map(site => (
                                                <PRACComponent  key={site.LocationId} item={site} type="Expired" />
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                </>
      


        </div>
        </article>
        </div>
        </div>
    );
};
