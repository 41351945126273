import React from 'react';
import Webcam from 'react-webcam';
import Cropper, { ReactCropperElement } from 'react-cropper';

interface IPhotoIDProps {
    imagePhotoID: string | null;
    isPhotoIDCroped: boolean;
    PhotoIDBlob: Blob | null;
    useCamPhotoID: boolean;
    useWebCamPhotoID: () => void;
    onChangePhotoID: (event: React.ChangeEvent<HTMLInputElement>) => void;
    cropperPhotoIdRef: React.RefObject<ReactCropperElement>;
    resetImagePhotoID: () => void;
    handleZoomInPhotoID: () => void;
    handleZoomOutPhotoID: () => void;
    getCropDataPhotoID: () => void;
    webcamPhotoIDRef: React.RefObject<Webcam>;
    captureImageFromWebcamPhotoID: () => void;
    rotateImageID: () => void;
    isMobile: boolean;
}

export const PhotoIDUpload = ({
    imagePhotoID,
    isPhotoIDCroped,
    PhotoIDBlob,
    useCamPhotoID,
    useWebCamPhotoID,
    onChangePhotoID,
    cropperPhotoIdRef,
    resetImagePhotoID,
    handleZoomInPhotoID,
    handleZoomOutPhotoID,
    getCropDataPhotoID,
    webcamPhotoIDRef,
    captureImageFromWebcamPhotoID,
    isMobile,
    rotateImageID
}: IPhotoIDProps) => {
    return (
        <div className="cco_form_row">
            <div className="cco_form_col cco_form_col_1">
                <div className="cco_form_wrap">
                    <div className="cco_form_fieldset cco_form_fieldset_2">
                        <div className="cco_form_fieldset_item">
                            <label htmlFor="ccoDoor">
                                2. Upload your Identification Photo
                            </label>
                            <div className="cco_form_field cco_form_field_0">


                                <div className="cco_form_field_body_p">

                                    {PhotoIDBlob &&
                                        <div style={{ margin: '10px 0px 0px 0px' }}>

                                            <img style={{ maxHeight: '180px', }} src={URL.createObjectURL(PhotoIDBlob)} alt="" />

                                        </div>
                                    }

                                    {!(isPhotoIDCroped && PhotoIDBlob) &&


                                        <label className="cco_form_field_label_small ccs_loader_relative_container" style={{ margin: '10px', display: imagePhotoID ? 'none' : 'block' }} htmlFor="ccoPhotoID" >

                                            {/* {(isPhotoIDCroped && PhotoIDBlob) ? (
                                                <>
                                                    <input type='file' id='ccoPhotoID' hidden onChange={onChangePhotoID} />
                                                    <img src={URL.createObjectURL(PhotoIDBlob)}
                                                        alt="user profile picture" style={{ width: 'auto' }}
                                                    />
                                                    <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">
                                                        Update Photo</span>
                                                    

                                                </>
                                            ) : ( */}
                                            <>
                                                <input type='file' id='ccoPhotoID' hidden onChange={onChangePhotoID} />
                                                <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                                <span className="cco_form_field_label_caption">Click to Upload</span>
                                            </>
                                            {/* )}  */}
                                        </label>
                                    }

                                    {!useCamPhotoID && !isMobile && !imagePhotoID && !isPhotoIDCroped &&
                                        <label className="cco_form_field_label_small ccs_loader_relative_container" onClick={useWebCamPhotoID} style={{ margin: '10px', display: imagePhotoID ? 'none' : 'block' }} htmlFor="ccowebcamPhotoID">
                                            <span onClick={useWebCamPhotoID} className="cco_form_field_label_icon cco_form_field_label_icon_camera"></span>
                                            <span className="cco_form_field_label_caption">Use Webcam</span>
                                        </label>
                                    }

                                </div>




                                {imagePhotoID && (
                                    <div className="cco_form_field_body" style={{ width: "100%", padding: '10px' }}>
                                        <div style={{ width: "100%", height: 'auto', textAlign: 'center' }}>
                                            <div className='no-mask' style={{ maxWidth: "100%", margin: "0 auto" }}>
                                                <Cropper
                                                    ref={cropperPhotoIdRef}
                                                    style={{ height: '100%', width: "100%", maxWidth: "400px", textAlign: 'center' }}
                                                    zoomTo={0}
                                                    initialAspectRatio={1}
                                                    src={imagePhotoID}
                                                    viewMode={1}
                                                    cropBoxMovable={true}
                                                    background={false}
                                                    responsive={true}
                                                    cropBoxResizable={true}
                                                    autoCropArea={1}
                                                    dragMode="move"
                                                    checkOrientation={false}
                                                    guides={true}
                                                />
                                            </div>
                                            <div className='cco_btn cco_btn_8'>
                                                <button onClick={rotateImageID}>Rotate</button>
                                                <button onClick={handleZoomInPhotoID}>Zoom In</button>
                                                <button onClick={handleZoomOutPhotoID}>Zoom Out</button>
                                                <button onClick={resetImagePhotoID}>Remove</button>
                                                <button onClick={getCropDataPhotoID}>Select Image</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {isPhotoIDCroped &&
                        <div className='cco_btn cco_btn_noBorder' style={{ marginTop: '-15px', marginBottom: '10px' }}>
                            <button onClick={resetImagePhotoID}>Reset</button>
                        </div>
                    }
                    <a href='http://www.nccco.org/ID' target='_blank'>Make sure your ID upload meets CCO guidelines</a>
                </div>

            </div>



            {useCamPhotoID &&
                <div className="cco_form_col cco_form_col_2">
                    <div className="cco_form_wrap" style={{ padding: '0px' }}>
                        <div className="cco_form_field cco_form_field">
                            <div className="cco_form_field_body">

                                <div className="cco_form_fieldset_item " style={{ textAlign: "center" }}>
                                    <div className="cco_form_label"></div>

                                    <><div className='webCam' style={{ width: '300px', height: '200px', border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden', display: 'contents' }}>

                                        <Webcam
                                            audio={false}
                                            ref={webcamPhotoIDRef}
                                            screenshotFormat='image/jpeg' />
                                    </div>
                                        <div className='cco_btn cco_btn_8'>
                                            <button onClick={captureImageFromWebcamPhotoID}>Capture</button>
                                            <button onClick={resetImagePhotoID}>Close</button>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </div >
    );
};


