import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { Header } from './components/Common/Header';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Login } from './containers/Login/Login';
import { Dashboard } from './containers/Dashboard/Dashboard';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import './sass/main.scss';
import { NotFound404 } from './components/Authentication/NotFound404';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { getUserData, setContactId, setTokenId, setUserData } from './tools/utils';
import { TestingPersonnel } from './containers/TestingPersonnel';
import { Credential } from './containers/Credentials/Credential';
import { MyCredential } from './containers/Credentials/MyCredential';
import { Account } from './containers/Account';
import { NewLocation } from './containers/NewLocation';
import { TSCEOT } from './containers/TSCEOT';
import ProtectedRoute from './components/Authentication/PrivateRoute';
import { tokenValidation } from './hooks/MSAL/TokenService';
import { TSCPRAC } from './containers/TSCPRAC';
import { NewPracLocation } from './containers/NewPracLocation';
import { CustomCAD } from './containers/NewCustomCAD';
import { CadManagement } from './containers/CADManagement';
import { routeTitle } from './routeMap';
import { msalConfig } from './authConfig';
import { Footer } from './components/Common/Footer';
import { PhotoManagement } from './containers/PhotoManagement';
import { CredentialJourney } from './containers/Credentials/CredentialJourney';
import { AvailableCredentials } from './containers/Credentials/AvailableCredentials';

const queryClient = new QueryClient();



const Pages = () => {

    const { instance, accounts } = useMsal();

    useEffect(() => {
        const activeAccount = accounts[0];
        if (activeAccount) {
          const userData = getUserData();
          if (userData) {
            const currentTime = new Date();
            const exp = new Date(userData.tokenExpiry);
            const isReadytoGetToken=(currentTime > exp || exp.getTime() - currentTime.getTime() <= 10 * 60 * 1000);
      
            if (userData.accessToken && isReadytoGetToken ) {
              const publicClientApplication = new PublicClientApplication(msalConfig);
              const request = {
                scopes: ['openid', msalConfig.auth.clientId], 
              };
              publicClientApplication.acquireTokenSilent(request).then(response => {
                if (response.accessToken) {
                  setUserData(response);
                  console.log('AcquireToken successful');
                  console.log(response);
                }
              });
            } else {
                const remainingTimeSeconds = Math.ceil((exp.getTime() - currentTime.getTime()) / 1000); // Remaining time in seconds
                const hours = Math.floor(remainingTimeSeconds / 3600);
                const minutes = Math.floor((remainingTimeSeconds % 3600) / 60);
                const seconds = remainingTimeSeconds % 60;
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                console.log(`Token will expire in ${formattedTime}.`);
              setTokenId(userData.accessToken);
            }

            if (!userData.contactId) {
                setContactId(activeAccount.idTokenClaims.extension_ContactId);
              } else {
                setContactId(userData.contactId);
              }
        
          }
        }



    
        const callbackId = instance.addEventCallback((event) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                setUserData(event.payload);
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (
        <><Header />
            <Routes>
                <Route path="/" element={accounts.length > 0 ? <Navigate to={'/dashboard'} /> : <Login />} />
                <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
                <Route path="/testing-personnel" element={<ProtectedRoute component={TestingPersonnel} />} />
                <Route path="/credential/:id?" element={<ProtectedRoute component={Credential} />} />
                <Route path="/my-credential/:id?" element={<ProtectedRoute component={MyCredential} />} />
                <Route path="/account" element={<ProtectedRoute component={Account} />} />
                <Route path="/tsc-eot" element={<ProtectedRoute component={TSCEOT} />} />
                <Route path="/tsc-eot/location-new" element={<ProtectedRoute component={NewLocation} />} />
                <Route path="/tsc-eot/location-edit/:id?" element={<ProtectedRoute component={NewLocation} />} />
                <Route path="/tsc-prac" element={<ProtectedRoute component={TSCPRAC} />} />
                <Route path="/tsc-prac/new-prac" element={<ProtectedRoute component={NewPracLocation} />} />
                <Route path="/tsc-prac/prac-edit" element={<ProtectedRoute component={NewPracLocation} />} />
                <Route path="/tsc-prac/custom-cad" element={<ProtectedRoute component={CadManagement} />} />
                <Route path="/tsc-prac/custom-cad/new-cad" element={<ProtectedRoute component={CustomCAD} />} />
                <Route path="/photo-management" element={<ProtectedRoute component={PhotoManagement} />} />
                <Route path="/credential-journey" element={<ProtectedRoute component={CredentialJourney} />} />
                <Route path="/available-credentials" element={<ProtectedRoute component={AvailableCredentials} />} />
                <Route path="*" element={<NotFound404 />} />
            </Routes>
           {accounts.length>0 ? <Footer /> :null} 
            </>
            
    );
};


const App = () => {
    const { pathname } = useLocation();
    document.title = routeTitle[pathname]?routeTitle[pathname]: ''  + ' | NCCCO';
    return (
        <QueryClientProvider client={queryClient}>
            <Pages />
        </QueryClientProvider>
    );
}

export default App;

