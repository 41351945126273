import React from 'react';

export const Footer = () => {

    return (
<footer id="main-footer">
        <div id="footer-bottom">
            <div className="container clearfix">
                <ul className="et-social-icons">
                    <li className="et-social-icon et-social-linkedin">
                        <a href="https://www.linkedin.com/company/ccocert" target="_blank" className="icon">
                            <span>LinkedIn</span>
                        </a>
                    </li>
                    <li className="et-social-icon et-social-facebook">
                        <a href="https://www.facebook.com/CCOcert.org/" target="_blank" className="icon">
                            <span>Facebook</span>
                        </a>
                    </li>
                    <li className="et-social-icon et-social-twitter">
                        <a href="https://twitter.com/NCCCOorg" target="_blank" className="icon">
                            <span>Twitter</span>
                        </a>
                    </li>
                    <li className="et-social-icon et-social-youtube">
                        <a href="https://www.youtube.com/@ccocert" target="_blank" className="icon">
                            <span>Youtube</span>
                        </a>
                    </li>
                </ul>
                <div id="footer-info" style={{paddingLeft:'5%'}}>Copyright 2022-2023. All rights reserved.</div>
            </div>
        </div>
    </footer>
    );
}


