import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/contactphotorequest';

export const useContactPhotoRequest = ({contactphotoId=null,contactId=null}) => {
    return useQuery<RootObject, Error>(['contactphotorequest',contactId,contactphotoId], async () => {
        const jsonResponse = await request({
            endpoint:contactId?'contactphotorequest/get-by-contactid':'contactphotorequest/get',
            data: {
                id: contactId?contactId:contactphotoId,
            },
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};
