import { allowedFileTypes } from '../tools/utils';
import * as yup from 'yup';

import 'yup-phone';

const getRequiredMessage = (field: string) => `The ${field} field is required`;
const checkMaxAllowedInMb = (value: number, maxValue: number = 1000000) => !(value / maxValue > 1);

export const newLocation = yup.object().shape({
    zip: yup.string().trim().required(getRequiredMessage('zip')),
    city: yup.string().trim().required(getRequiredMessage('city')),
    street1: yup.string().trim().required(getRequiredMessage('street1')),
    state: yup.string().test('select-state', getRequiredMessage('state'), value => value !== 'default'),
    country: yup.string().test('select-country', getRequiredMessage('country'), value => value !== 'default'),
    timezone: yup.string().test('select-timezone', getRequiredMessage('timezone'), value => value !== 'default'),

    //Billing Information Form in Test Site Location
    Zip: yup.string().required(getRequiredMessage('Zip')),
    City: yup.string().trim().required(getRequiredMessage('City')),
    FirstName: yup.string().trim().required(getRequiredMessage('First Name')),
    LastName: yup.string().trim().required(getRequiredMessage('Last Name')),
    Address: yup.string().trim().required(getRequiredMessage('Address')),
    State: yup.string().trim().required(getRequiredMessage('State')),
    BillToEmail: yup.string().trim().email('Invalid email').required(getRequiredMessage('Email')),
    BillingPhone: yup.string().trim().required(getRequiredMessage('phone')),
    BillingCountry: yup.string().trim().required(getRequiredMessage('country')),
    attestation: yup.array().min(6, 'All attestations are required').of(yup.string().required()).required(),
    internetSpeed: yup
        .string()
        .test(
            'select-internet-speed',
            'It is required to have the minimum download/upload speed',
            value => 'yes' === value
        ),
    doorSign: yup
        .mixed()
        .required(getRequiredMessage('door sign'))
        .test('fileSize', 'The file is too large (Max 5 MB)', value => {
            if (value) {
                return checkMaxAllowedInMb(value.size, 5000000);
            }

            return true;
        }).test('fileType', 'File type not supported.', value => {
            if (allowedFileTypes.indexOf(value?.type) === -1) {
           return false; 
       }
       return true;
   }),
    sitePlan: yup
        .mixed()
        .required(getRequiredMessage('site plan'))
        .test('fileSize', 'The file is too large (Max 10 MB)', value => {
            if (value) {
                return checkMaxAllowedInMb(value.size, 10000000);
            }

            return true;
        }).test('fileType', 'File type not supported.', value => {
            if (allowedFileTypes.indexOf(value?.type) === -1) {
           return false; 
       }
       return true;
   }),
    termsAndConditions: yup
        .mixed()
        .required(getRequiredMessage('terms & conditions'))
        .test('fileSize', 'The file is too large (Max 10 MB)', value => {
            if (value) {
                return checkMaxAllowedInMb(value.size, 10000000);
            }

            return true;
        }).test('fileType', 'File type not supported.', value => {
            if (value?.type !='application/pdf') {
           return false; 
       }
       return true;
   }),

    email: yup.string().trim().email('Invalid email').required(getRequiredMessage('email')),
    fullName: yup.string().trim().required(getRequiredMessage('full name')),
    phoneNumber: yup.string().required(getRequiredMessage('phone number')),
    emailConfirmation: yup
        .string().trim()
        .email('Invalid email')
        .required(getRequiredMessage('email confirmation'))
        .oneOf([yup.ref('email')], 'Emails must match'),
    companyName: yup.string().trim().required(getRequiredMessage('company name')),
    isASecureFacility: yup
        .string()
        .test('select-secure-facility', getRequiredMessage('secure facility'), value => value !== 'default'),
    numberOfCandidateTestingStations: yup.number().required(getRequiredMessage('number of candidate testing stations')).min(1, 'Maximum capacity permitted is 1.').max(42, 'Maximum capacity permitted is 42.'),
    additionalSecurity: yup.string().when('isASecureFacility', {
        is: 'yes',
        then: yup.string().required(getRequiredMessage('additional security')),
    }),
});
