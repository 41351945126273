import type { RootObject } from '../types/states';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const useStates = (countryId?: string) => {
    return useQuery<RootObject, Error>(
        ['states', countryId],
        async () => {
            const jsonResponse = await request({
                endpoint: 'locations/get-provinces',
                data: {
                    countryId,
                },
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled: !!countryId,
        }
    );
};
