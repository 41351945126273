import { useState, cloneElement, useEffect } from 'react';

import type { ReactElement } from 'react';

export function useMultiStepForm({ single }: { single?: boolean }, steps: ReactElement[]) {
    const [skipUsed, setSkipUsed] = useState(false);
    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    useEffect(() => {
        if (single) {
            setCurrentStepIndex(steps.length - 1);
        }
    }, [single, steps.length]);

    function next() {
        setCurrentStepIndex(i => {
            if (i >= steps.length - 1) {
                return i;
            }

            return i + 1;
        });
    }

    function back() {
        setCurrentStepIndex(i => {
            if (i <= 0) {
                return i;
            }

            return i - 1;
        });
    }

    function goTo(index: number) {
        setSkipUsed(true);
        setCurrentStepIndex(index);
    }

    function goToLastStep() {
        setSkipUsed(false);
        setCurrentStepIndex(steps.length - 1);
    }

    return {
        next,
        back,
        goTo,
        steps,
        skipUsed,
        goToLastStep,
        currentStepIndex,
        step: cloneElement(steps[currentStepIndex], {
            goTo,
        }),
        isFirstStep: 0 === currentStepIndex,
        isLastStep: currentStepIndex === steps.length - 1,
    };
}
