export const routeTitle = {
    '/': ' Member Portal',
    '/account':"Account Details",
    '/dashboard': 'Dashboard',
    '/testing-personnel': 'Testing Personnel',
    '/tsc-eot':"TSC-EOT",
    '/tsc-prac':"TSC-Practical",
    '/tsc-prac/new-prac':'New TSC-Practical',
    '/credential':'Credential',
    '/my-credential':'My Credential',
    '/tsc-prac/custom-cad':'CADs Management',
    '/tsc-prac/custom-cad/new-cad':'New Custom CADs',
    '/tsc-prac/prac-edit':"Edit Practical",
    '/photo-management':"Photo Management"
  };