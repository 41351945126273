import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Link, useLocation } from 'react-router-dom';
import { loginRequest } from '../../authConfig';
import { SideMenu } from './SideMenu';
export const Header = () => {

    const { instance, accounts } = useMsal();
    let activeAccount;

    if (accounts.length > 0) {
        activeAccount = accounts[0]
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest)
            .catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        const logoutRequest = {
            account: activeAccount,
            postLogoutRedirectUri: "/",
          };
        instance.logoutRedirect(logoutRequest);
        localStorage.removeItem('msal.userData')


    };

 



    const [isDropDownActive, setDropDownActive] = useState(false);
    const [isSidbarActive, setSideBarActive] = useState(false);
    const [isDashboard, setIsDashboard] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsDashboard(location.pathname === '/dashboard');
    }, [location]);

    const toggleClass = () => {
        setDropDownActive(!isDropDownActive);
    };

    const toggleSidebarClass = () => {
        setSideBarActive(!isSidbarActive);
    };

    return (
        <div id="page-container">
            {activeAccount && (
                <header className="cco_section cco_section_1">
                    <div className="cco_row cco_row_5">
                        <div className="cco_col cco_col_9">
                            <div className="cco_btn cco_btn_4">
                                <button id="ccoSidebarToggle" onClick={toggleSidebarClass}>
                                    <span>Toggle Menu</span>
                                </button>
                            </div>
                        </div>

                        <div className="cco_col cco_col_10">
                            <div className="cco_img cco_img_0">
                                <a className="cco_img_wrap" href="/dashboard">
                                    <img src="https://portal-test.nccco.org/wp-content/themes/Divi-Child/assets/images/Logo_CCO_2.png" alt="NCCCO" />
                                </a>
                            </div>
                        </div>

                        <div className="cco_col cco_col_11">
                        
                            <div className={`cco_dropdown cco_dropdown_0 ${isDropDownActive ? 'active' : null}`} onClick={toggleClass}>
                                <button className={'cco_dropdown_toggle'}>

                                    <img
                                        className="cco_dropdown_toggle_img"
                                        src={`https://portal-test.nccco.org/wp-content/themes/Divi-Child/assets/images/userGrey.png`
                                        }
                                        alt=""
                                    />

                                    <span className="cco_dropdown_toggle_text">
                                        {activeAccount?.username}
                                    </span>
                                </button>

                                <div className="cco_dropdown_content">
                                    <div className="cco_text cco_text_4">
                                        <div className="cco_text_inner">Role Management</div>
                                    </div>
                                    <ul className="cco_menu cco_menu_1">
                                        <li className="cco_menu_item cco_menu_item_icon_avatar">
                                            {/* <a href="/testing-personnel">Testing Personnel</a> */}
                                            <Link to={"/testing-personnel"} state={activeAccount} >Testing Personnel</Link>

                                        </li>
                                    </ul>
                                    <div className="cco_text cco_text_4">
                                        <div className="cco_text_inner">
                                            Account Management
                                        </div>
                                    </div>
                                    <ul className="cco_menu cco_menu_1">
                                        <li className="cco_menu_item cco_menu_item_icon_gear">
                                            {/* <a href="/account">Account Details</a> */}
                                            <Link to={"/account"} state={activeAccount} >Account Details</Link>
                                        </li>
                                        <li className="cco_menu_item cco_menu_item_icon_logout">
                                            <a href="javascript:;" onClick={handleLogoutRedirect}>Sign Out</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

            )}


            {!activeAccount && (
                <div className='loginPage'>
                    <header className="cco_header">
                        <div className="cco_header_container">
                            <div className="cco_header_logo">
                                <a href="/" rel='noreferrer'>
                                    <img
                                        src='https://portal-test.nccco.org/wp-content/themes/Divi-Child/assets/images/Logo_CCO_2.png'
                                        alt="Logo"
                                    />
                                </a>
                            </div>
                            <div className="cco_header_nav">
                                <nav className="cco_header_nav_desktop">
                                    <ul className="cco_header_list_1">
                                        <li className="cco_header_list_1_item">
                                            <a href="/">myCCO</a>
                                        </li>
                                        <li className="cco_header_list_1_item">
                                            <a href="https://nccco.org/" target="_blank" rel='noreferrer'>
                                                NCCCO.ORG
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="cco_header_list_2">
                                        <li className="cco_header_list_2_item cco_header_list_2_item_register">
                                            <a href='javascript:void(0)' onClick={handleLoginRedirect} rel='noreferrer'>
                                                {'Login/Register'}
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="cco_header_nav_mobile">
                                    <div className="cco_header_nav_mobile_closed">
                                        <button className="cco_header_toggle">
                                            <span className="cco_header_toggle_icon" />
                                        </button>
                                        <ul className="cco_header_list_3">
                                            <li className="cco_header_list_3_item">
                                                <a href="/">myCCO</a>
                                            </li>
                                            <li className="cco_header_list_3_item">
                                                <a href="https://nccco.org/" target="_blank" rel='noreferrer'>
                                                    NCCCO.ORG
                                                </a>
                                            </li>
                                            <li>
                                                <ul className="cco_header_list_4">
                                                    <li className="cco_header_list_4_item cco_header_list_4_item_register">
                                                        <a href='javascript:void(0)' onClick={handleLoginRedirect}>
                                                            {'Login/Register'}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            )}
            {activeAccount && (
                <SideMenu isDashboard={isDashboard} isSidbarActive={isSidbarActive}/>
            )}





        </div>
    );
}


