import React, { useState } from 'react';
import { useUser, useUserProfilePicture } from '../../hooks/useUser';
import { useCerts } from '../../hooks/useCerts';
import { useQuery } from '@tanstack/react-query';
import { request } from '../../services/request';
import { Root } from '../../types/dashboard';
import { customURLs, formatAddress, getToken } from '../../tools/utils';
import { RootExam } from '../../types/exams';
import { Loader } from '../../components/Loader';
import { Accordion, CertificateAccordion } from '../../components/Accordion';
import { PhotoNotificationBanner as PhotoAlertBanner } from '../../components/Common/NotificationBanner';



export const Dashboard = () => {
  
    const query = useUser();
    const certs = useCerts();
    const userProfileQuery = useUserProfilePicture();
    const userProfileData = userProfileQuery.data?.data.Data;
    const token = getToken();


    const exams = useQuery<RootExam, Error>(['exams'], async () => {
        const jsonResponse = await request({
            endpoint: 'Exams'
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });

    const [profilePictureLoading, setprofilePictureLoading] = useState(true);

    const handleImageLoaded = () => {
      setprofilePictureLoading(false);
    };

    //const userProfileData = userProfilePicture.data;
    const data = (query.data?.data || {}) as unknown as Root['data'];
    const objExams = (exams.data?.data || {}) as unknown as RootExam['data'];
    const dataCerts = (certs.data?.data || []) as unknown as Root['data']['Certificates'];
    const address = (data?.Addresses?.length || 0) > 0 ? formatAddress(data.Addresses[0]) : null;


    return (
        <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
        <article className="react-container" >

        <PhotoAlertBanner showBanner={userProfileQuery.isFetched && !userProfileQuery.isError && (!userProfileData || userProfileData.Status==='Rejected')} message='​URGENT: Upload your photo as soon as possible. Failure to do so may delay your ability to test or earn your certification' />
        
        <div className={userProfileQuery.isFetched && (!userProfileData || userProfileData.Status==='Rejected') ? "cco_section bannerVisible" : "cco_section cco_section_3"}>
                {!query.isLoading && !query.error && (
                <div className="cco_row cco_row_4">
                    <div className="cco_col">
                        <div className="cco_text cco_text_0">
                            <div className="cco_text_inner">
                                <strong>Hi {data.Name.First},</strong> welcome to your Dashboard!
                            </div>
                        </div>
                    </div>
                </div>
            )}
             <div className="cco_row cco_row_0">
                    {query.isLoading || userProfileQuery.isLoading ? (
                        <Loader />
                    ) : query.error ? (
                        <p>{query.error.message}</p>
                    ) : (
                        <>
                            <div className="cco_col cco_col_4">
                                <div className="cco_img cco_img_1">
                                    <a className="cco_img_wrap" href={data.ContactPhotoRequest.length>0?'javascript:void(0)':'/photo-management'}>
                                        {data.ContactPhotoRequest.length<=0 && !data.myCCOPicture &&
                                            <><img src="https://trueb2c.live/wp-content/themes/Divi-Child/assets/images/Missing_Avatar_2.svg" alt="" />
                                                <div className="cco_img_icon">
                                                    <span className="cco_img_icon_path_0"></span>
                                                    <span className="cco_img_icon_path_1"></span>
                                                    <span className="cco_img_icon_path_2"></span>
                                                </div>
                                                </>
                                                }
                                        <div className={data && data.ContactPhotoRequest.length>0?'cco_img_caption_pending':'cco_img_caption'}>{data.ContactPhotoRequest.length>0?'PENDING APPROVAL':'Update Photo'}</div>
                                        <div className={data && data.ContactPhotoRequest.length>0?'cco_img_caption_pending cco_img_caption_hover':'cco_img_caption cco_img_caption_hover'}>{data.ContactPhotoRequest.length>0?'PENDING APPROVAL':'Update Photo'}</div>
                                              
                                        {data.ContactPhotoRequest.length<=0 || data.myCCOPicture ? (
                                            <>

                                                {profilePictureLoading &&
                                                    <div style={{ marginTop: '30%' }}>
                                                        <Loader />
                                                    </div>}
                                                <img src={data && data.myCCOPicture} onLoad={handleImageLoaded} style={{ display: profilePictureLoading ? 'none' : 'block' }} alt="user profile picture" />
                                              
                                              {/* {data && data.ContactPhotoRequest.length<=0 &&
                                              <div className="cco_img_caption cco_img_caption_hover">Upload Photo</div>
                                              }   */}
                                            </>
                                        ) : (
                                            <>
                                                <img src="https://trueb2c.live/wp-content/themes/Divi-Child/assets/images/Missing_Avatar_2.svg" alt="" />

                                                <div className="cco_img_icon">
                                                    <span className="cco_img_icon_path_0"></span>
                                                    <span className="cco_img_icon_path_1"></span>
                                                    <span className="cco_img_icon_path_2"></span>
                                                </div>
                                                <div className="cco_img_caption">{data.ContactPhotoRequest.length>0?'PENDING APPROVAL':'Update Photo'}</div>
                                                 
                                            </>
                                        )}
                                    </a>
                                </div>
                            </div>

                            {/* <div className="cco_col cco_col_5"> */}
                            <div className="cco_col cco_col_12">
                                <div className="cco_text cco_text_1">
                                    <div className="cco_text_inner">
                                        <h1>
                                            {data.Name.FullName}
                                            <span className="divider" />
                                            <small>
                                                CCO ID <strong>{data.SecondaryKeys[0].Key}</strong>
                                            </small>
                                        </h1>
                                    </div>
                                </div>
                                <table className="cco_table cco_table_0">
                                    <tbody className="cco_table_body">
                                        <tr className="cco_table_row">
                                            {(data.Addresses.length > 0 || address) && (
                                                <td className="cco_table_cell">
                                                    {address ? `Address: ${address}` : null}

                                                    {/* {data.Addresses[0].Street2 && (
                        <>
                            <br />
                            {data.Addresses[0].Street2}
                        </>
                    )} */}
                                                </td>
                                            )}
                                            <td className="cco_table_cell cco_table_cell_large">
                                                {data.PhoneNumbers.length > 0 && data.PhoneNumbers[0].Phone && (
                                                    <div className="cco_blurb cco_blurb_has_border cco_blurb_0">
                                                        <div className="cco_blurb_label">Phone</div>
                                                        <div className="cco_blurb_value">
                                                            <strong>{data.PhoneNumbers[0].Phone}</strong>
                                                        </div>
                                                    </div>
                                                )}

                                                {data.EmailAddresses.length > 0 && data.EmailAddresses[0].Email && (
                                                    <div className="cco_blurb cco_blurb_has_border cco_blurb_0">
                                                        <div className="cco_blurb_label">Email</div>
                                                        <div className="cco_blurb_value">
                                                            <strong>{data.EmailAddresses[0].Email}</strong>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}
                </div>

            {data.IsProfileCompleted && (
                <>
                    <div className="cco_row cco_row_2">
                        <div className="cco_col">
                            <div className="cco_text cco_text_2">
                                <div className="cco_text_inner">
                                    <h2>Apply Now For</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cco_row cco_row_1">
                        <div className="cco_col">
                            <ul className="cco_menu cco_menu_0">
                                <li className="cco_menu_item cco_menu_item_primary"   >
                                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                    <a style={{height:"auto"}}
                                        href={`${
                                             customURLs?.onlineWrittenTestingUrl?.button_url
                                                ? customURLs.onlineWrittenTestingUrl.button_url + '?id_token=' + token
                                                : '#'
                                        }`}
                                        target="_blank" rel='noreferrer'>
                                        {customURLs?.onlineWrittenTestingUrl?.button_text || 'Button Text'}
                                    </a>
                                    <div style={{textAlign:"center"}}>
                                    <span>
                                   </span>
                                    </div>
                                   
                                    
                                    

                                </li>

                                <li className="cco_menu_item cco_menu_item_primary">
                                     <a style={{height:"auto"}}
                                        href={`${
                                            customURLs?.paperPencilTestingUrl?.button_url
                                                ? customURLs.paperPencilTestingUrl.button_url + '?id_token=' + token
                                                : '#'
                                        }`}
                                        target="_blank" rel='noreferrer'>
                                        {customURLs?.paperPencilTestingUrl?.button_text || 'Button Text'}
                                    </a>
                                    <div style={{textAlign:"center"}}>
                                    <span>
                                 
                                   </span>
                                    </div>
                                </li>

                                <li className="cco_menu_item cco_menu_item_primary" style={{marginBottom:"0px"}}>
                                    <a style={{height:"auto"}}
                                        href={`${
                                            customURLs?.practicalTestingUrl?.button_url
                                                ? customURLs.practicalTestingUrl.button_url + '?id_token=' + token
                                                : '#'
                                        }`}
                                        target="_blank" rel='noreferrer'>
                                        {customURLs?.practicalTestingUrl?.button_text || 'Button Text'}
                                    </a>
                                </li>
                            </ul>
                            <div style={{textAlign:"center",marginTop:'-10px'}}>
                                    <span>
                                    {/* <a className='ccolinks' href={'https://www.nccco.org/nccco/get-cco-certified/how-to-test-written-exams#PPT'} target="_blank">What is PPT?</a> */}
                                    <a className='ccolinks' href={'https://www.nccco.org/testingoptions'} target="_blank">Learn about written testing options</a>
                                    
                                   </span>
                                    </div>
                            
                        </div>
                    </div>

                    <div className="cco_row cco_row_2">
                        <div className="cco_col">
                            <div className="cco_text cco_text_2">
                                <div className="cco_text_inner">
                                    <h2>Candidate Resources</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cco_row cco_row_11">
                        <div className="cco_col">
                            <div className="cco_text cco_text_9">
                                <div className="cco_text_inner">
                                    <h3>New to OPT or EOT testing? Take a test drive!</h3>
                                    <p>
                                        Familiarize yourself with CCO’s new exam delivery system. CCO has created a free demo exam allowing you to navigate the
                                        exam delivery system and try out the on-screen calculator, working with load chart PDF files, reviewing/flagging
                                        questions, and more.
                                    </p>
                                </div>
                            </div>

                            <div className="cco_btn cco_btn_6">
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a href="https://portal2.examstudio.com/?pl=YFVsTRkxRjQzBabqwjRq3A%3d%3d" target="_blank">
                                    Launch Demo Exam
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className="cco_row cco_row_2">
                <div className="cco_col">
                    <div className="cco_text cco_text_2">
                        <div className="cco_text_inner">
                            <h2>My Certifications</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cco_row cco_row_3">
                <div className="cco_col">
                    {certs.isLoading || query.isLoading ? (
                        <Loader />
                    ) : certs.error || query.error ? (
                        <p>{certs.error ? certs.error.message : query.error ? query.error.message : 'Unknown error'}</p>
                    ) : dataCerts.length > 0 ? (
                        <ul className="cco_accordion">
                            {dataCerts.map((certificate, id) => (
                                <CertificateAccordion key={id} certificate={certificate} name={data.Name.FullName || null} />
                            ))}
                        </ul>
                    ) : (
                        <p>No certificates found</p>
                    )}
                </div>
            </div>

            <div className="cco_row cco_row_7">
                <div className="cco_col cco_col_6">
                    <div className="cco_text cco_text_10">
                        <div className="cco_text_inner">
                            <h2>Upcoming Exams / Pending Results</h2>
                        </div>
                    </div>
                </div>

                {data.IsProfileCompleted && (
                    <div className="cco_col cco_col_7">
                        <div className="cco_btn cco_btn_3">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a
                                href={`${
                                    customURLs?.esoCandidateDashboardUrl?.button_url
                                        ? customURLs.esoCandidateDashboardUrl.button_url + '?id_token=' + token
                                        : '#'
                                }`}
                                target="_blank">
                                {customURLs?.esoCandidateDashboardUrl?.button_text || 'Button Text'}
                            </a>
                        </div>
                    </div>
                )}
            </div>

            <div className="cco_row cco_row_3">
                <div className="cco_col">
                    {exams.isLoading ? (
                        <Loader />
                    ) : exams.error ? (
                        <p>{exams.error.message}</p>
                    ) : objExams.upcomingExams.length > 0 ? (
                        <ul className="cco_accordion">
                            {objExams.upcomingExams.map((exam, index) => (
                                <Accordion key={index} exam={exam} />
                            ))}
                        </ul>
                    ) : (
                        <p>No upcoming exams found</p>
                    )}
                </div>
            </div>

            <div className="cco_row cco_row_2">
                <div className="cco_col">
                    <div className="cco_text cco_text_2">
                        <div className="cco_text_inner">
                            <h2>
                                Exam History * <small>(previous 12 months)</small>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cco_row cco_row_10">
                <div className="cco_col">
                    {exams.isLoading ? (
                        <Loader />
                    ) : exams.error ? (
                        <p>{exams.error.message}</p>
                    ) : objExams.examHistory.length > 0 ? (
                        <ul className="cco_accordion">
                            {objExams.examHistory.map((exam, index) => (
                                <Accordion key={index} exam={exam} />
                            ))}
                        </ul>
                    ) : (
                        <p>No history exams found</p>
                    )}
                </div>
            </div>

            <div className="cco_row cco_row_1">
                <div className="cco_col">
                    <div className="cco_text cco_text_15">
                        <div className="cco_text_inner">
                            <p>
                                <strong>*Examination results are subject to CCO determinations of validity and other appropriate corrections.</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

            </article>
    
            <div className="cco_section cco_section_4">
                <div className="cco_row">
                    <div className="cco_col">
                        <div className="cco_ad cco_ad_0">
                            <ins data-revive-zoneid="15248" data-revive-id="727bec5e09208690b050ccfc6a45d384"></ins>
                        </div>
                        <div className="cco_ad cco_ad_1">
                            <ins data-revive-zoneid="15249" data-revive-id="727bec5e09208690b050ccfc6a45d384"></ins>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    );
};

