import type { IValues, IOtherTSC } from '../../containers/NewLocation/valuesTypes';

import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { screenedValues } from '../../tools/constants';
import { OtherTSC } from '../../containers/NewLocation/SummaryComponents/OtherTSC';
import { Attestation } from '../../containers/NewLocation/SummaryComponents/Attestation';
import { InternetSpeed } from '../../containers/NewLocation/SummaryComponents/InternetSpeed';
import { LocationDoorSign } from '../../containers/NewLocation/SummaryComponents/LocationDoorSign';
import { LocationSitePlan } from '../../containers/NewLocation/SummaryComponents/LocationSitePlan';
import { LocationInformation } from '../../containers/NewLocation/SummaryComponents/LocationInformation';
import { TestSiteRepresentativeInformation } from '../../containers/NewLocation/SummaryComponents/TestSiteRepresentativeInformation';
import { BillingInformation } from './SummaryComponents/BillingInformation';
import { LocationProductData } from '../../types/product';

export const Summary = ({
    goTo,
    approved,
    selectedTSCs,
    singlePage = false,
    CreditCard,
    PaymentData
}: {
    goTo?: (step: number) => void;
    singlePage?: boolean;
    approved: boolean;
    selectedTSCs: IOtherTSC[];
    CreditCard:string,
    PaymentData:LocationProductData
}) => {
    const { setFieldTouched, values } = useFormikContext<IValues>();

    useEffect(() => {
        if (singlePage) {
            for (const [_, value] of Object.entries(screenedValues)) {
                value.forEach((item: keyof IValues) => setFieldTouched(item, true, true));
            }
        }
    }, [setFieldTouched, singlePage]);

    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                {!singlePage && <h2 className="cco_form_heading">Summary</h2>}

                <div className="cco_form_wrap">
                    <div className="cco_form_row cco_form_row_0">
                        <div className="cco_form_col cco_form_col_3">
                            <LocationInformation
                                handler={goTo}
                                single={singlePage}
                                editable={!singlePage /* values.prevState.canBeEdited */}
                            />

                            <LocationDoorSign
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />
                        </div>

                        <div className="cco_form_col cco_form_col_3">
                            <LocationSitePlan
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />

                            <TestSiteRepresentativeInformation
                                handler={goTo}
                                single={singlePage}
                                editable={!singlePage || values.prevState.canBeEdited}
                            />
                        </div>

                        <div className="cco_form_col cco_form_col_3">
                            <InternetSpeed
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />

                            <Attestation
                                handler={goTo}
                                editable={!singlePage /* !singlePage && values.prevState.canBeEdited */}
                            />

                            {approved && <OtherTSC handler={goTo} editable={true} selectedTSCs={selectedTSCs} />}
                        </div>
                        <div className="cco_form_col cco_form_col_3" hidden={singlePage}>
                            <BillingInformation
                                handler={goTo}
                                editable={!singlePage} 
                                CreditCard={CreditCard}
                                Amount={PaymentData.ProductPrice}
                            />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
