import React from 'react';
import { Link } from 'react-router-dom';
import { TokenPost } from '../../hooks/useTokenPost';
import { getToken } from '../../tools/utils';


export const SideMenu = ({ isSidbarActive, isDashboard }) => {

   const handleClick=(event)=>{
    event.preventDefault();
   const form= document.getElementById('myForm') as HTMLFormElement;
   form.submit();
    }

    return (
        <><div className={`cco_sidebar_overlay ${isSidbarActive ? 'cco_sidebar_overlay_active' : ''}`} id="ccoSidebarOverlay" /><aside className={`cco_sidebar ${isSidbarActive ? 'cco_sidebar_active' : null}`} id="ccoSidebar">
            <div className="cco_sidebar_inner">
                <ul className="cco_menu cco_menu_5">
                    <li className={`cco_menu_item cco_menu_item_icon_dashboard ${isDashboard ? 'cco_menu_item_active' : ''}`}>
                        {/* <a href="/dashboard">Dashboard</a> */}
                        <Link to={'/dashboard'}>Dashboard</Link>
                    </li>
                    <li className="cco_menu_item">
                        <a href="https://nccco.org" target="_blank" rel='noreferrer'>Website</a>
                    </li>
                    <li className="cco_menu_item">
                        <a href="https://www.nccco.org/nccco/contact-us" target="_blank" rel='noreferrer'>Contact Us</a>
                    </li>
                    <li className="cco_menu_item">
                        <form action="https://trueb2c.live/courses-dashboard/" target="_blank"  id='myForm' method="post">
                            <input type="hidden" name="state" value="https://trueb2c.live/wp-admin/" />
                            <input type="hidden" name="id_token" value={getToken()} />
                            {/* <button type="submit">Assign Token (test)</button> */}
                            <a href="#" onClick={handleClick} >Login trueb2c</a>
                        </form>

                        {/* <a href="#" onClick={TokenPost} target='' rel='noreferrer'>Assign Token (test)</a> */}
                    </li>
                </ul>
            </div>
        </aside></>



    );
}


