import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/mycredential';

export const useMyCredential = (contactCredentialId?: string | null) => {
    return useQuery<RootObject, Error>(['mycredential',contactCredentialId], async () => {
        const jsonResponse = await request({
            endpoint: 'my-contact-credential',
            data: {
                contactcredentialId: contactCredentialId,
            },
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};
