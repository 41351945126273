import type { Certificate } from '../../types/dashboard';

interface ICertificateAccordionProps {
    name: string | null;
    certificate: Certificate;
}

export const CertificateAccordion = ({ certificate, name }: ICertificateAccordionProps) => {
    return (
        <li className="cco_accordion_item active">
            <div className="cco_accordion_item_body">
                <table className="cco_table">
                    <tbody className="cco_table_body">
                        <tr className="cco_table_row">
                            <td className="cco_table_cell cco_table_cell_large">
                                <div className="cco_blurb cco_blurb_0">
                                    <div className="cco_blurb_label">CCO Certified</div>
                                    <div className="cco_blurb_value">
                                        <strong>{certificate.CertificateType || '-'}</strong>
                                    </div>
                                </div>
                                <div className="cco_blurb cco_blurb_0">
                                    <div className="cco_blurb_label">Designations Held</div>
                                    <div className="cco_blurb_value">
                                        <strong>{certificate.SubTypeString || '-'}</strong>
                                    </div>
                                </div>
                            </td>
                            <td className="cco_table_cell">
                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                    <div className="cco_blurb_label">Name</div>
                                    <div className="cco_blurb_value">
                                        <strong>{name || '-'}</strong>
                                    </div>
                                </div>
                                <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                    <div className="cco_blurb_label">Expire Date</div>
                                    <div className="cco_blurb_value">
                                        <strong>{certificate.ExpirationDate || '-'}</strong>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </li>
    );
};
