import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export interface Root {
    Data: Daum[];
    Success: boolean;
    Message: string;
    ApiCode: number;
}

export interface Daum {
    FirstName: string;
    LastName: string;
    ContactSubscriptionExpiryDate: string;
    ContactSubscriptionCredentialExpiryDate: string;
    CredentialName: string;
    CredentialId: string;
    ContactSubscriptionId: string;
    ContactId: string;
    PersonalEmail: string;
    TscEmail: any;
    CandidateId: string;
}

export interface RootObject {
    success: boolean;
    data: Root;
}

export const useActiveTSCs = (query?: string) => {
    return useQuery<RootObject, Error>(
        ['query', query],
        async ({ signal }) => {
            const jsonResponse = await request({
                endpoint: 'locations/search-tsc',
                data: {
                    query,
                    credential: 'TSC-EOT',
                },
                signal,
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled: !!query,
        }
    );
};
