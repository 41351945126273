import type { IValuesPrac } from '../../../containers/NewPracLocation/valuesTypes';

import classNames from 'classnames';

import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { screenedValuesPrac } from '../../../tools/constants';

export const TestSiteRepresentativeInformation = ({
    handler,
    editable = true,
    single = false,
}: {
    handler?: (step: number) => void;
    editable?: boolean;
    single?: boolean;
}) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValuesPrac>();

    const [isExpanded, setIsExpanded] = useState(single);

    const isFormCompleted = useMemo(
        () => screenedValuesPrac[3].every(field => !errors[field] && touched[field]),
        [errors, touched]
    );

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>5. Test Site Representative Information</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted,
                            })}
                        >
                            {isFormCompleted
                                ? 'Completed'
                                : submitCount < 1
                                    ? 'In Progress'
                                    : 'Please fill out all fields'}
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">View</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Full Name</div>

                        <div className="cco_blurb_value">
                            <strong>{values.fullName || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Phone</div>
                        <div className="cco_blurb_value">
                            <strong>{values.phoneNumber || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Email</div>
                        <div className="cco_blurb_value">
                            <strong>{values.email || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div>
                        <p className="cco_text_1">TSR Agreement</p>

                        <div className="cco_blurb cco_blurb_2">
                            <div className="cco_blurb_img">
                                {(values.termsAndConditions instanceof File &&
                                    values.termsAndConditions.type.includes('image')) ||
                                    ('string' === typeof values.termsAndConditions &&
                                        values.termsAndConditions &&
                                        !values.termsAndConditions.includes('.pdf') ? (
                                        <img
                                            onLoad={e => {
                                                const src = e.currentTarget.src;

                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                            }}
                                            src={
                                                // @ts-ignore
                                                values.termsAndConditions instanceof File
                                                    ? URL.createObjectURL(values.termsAndConditions)
                                                    : values.termsAndConditions
                                            }
                                            alt="terms & conditions image"
                                        />
                                    ) : (
                                        <span className="cco_blurb_img_icon cco_blurb_img_icon_file" />
                                    ))}
                            </div>

                            {values.termsAndConditions && (
                                <div className="cco_blurb_title">
                                    {values.termsAndConditions instanceof File && values.termsAndConditions.type.includes('application/pdf') ? values.termsAndConditions.name : null /* values.termsAndConditions */}
                                    {(!single && !values.termsAndConditions.type.includes('application/pdf'))?(<p className='cco_blurb cco_blurb_error cco_blurb_3'>File type not supported.</p>):(null)}
                                </div>
                            )}
                        </div>
                    </div>

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(3);
                                    e.preventDefault();
                                }}
                            >
                                Edit
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
