import type { IOtherTSC } from '../../../containers/NewPracLocation/valuesTypes';

import classNames from 'classnames';

import { useState } from 'react';

export const OtherTSC = ({
    handler,
    selectedTSCs,
    editable = true,
}: {
    handler?: (step: number) => void;
    editable?: boolean;
    selectedTSCs: IOtherTSC[];
}) => {
    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>7. Other Test Site Coordinators</h3>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">View</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    {selectedTSCs.length > 0 ? (
                        selectedTSCs.map(tsc => {
                            return (
                                <div key={tsc.id} className="cco_fieldset cco_fieldset_0">
                                    <div className="cco_fieldset_item cco_fieldset_item_large">{tsc.name}</div>
                                    {tsc.primary && (
                                        <div className="cco_fieldset_item">
                                            <div className="cco_blurb cco_blurb_5">Primary</div>
                                        </div>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <p>No other TSCs found</p>
                    )}

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(5);
                                    e.preventDefault();
                                }}
                            >
                                Edit
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
