import { Configuration, LogLevel } from "@azure/msal-browser";

const {REACT_APP_CLIENT_ID,REACT_APP_AUTHORITY,REACT_APP_KNOWNAUTHORITIES}=process.env;
export const msalConfig:Configuration = {
    auth: {
        clientId:REACT_APP_CLIENT_ID, 
        authority: REACT_APP_AUTHORITY, 
        knownAuthorities: [REACT_APP_KNOWNAUTHORITIES], 
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: false,    
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,

    
    },
    // system: {	
    //     loggerOptions: {	
    //         loggerCallback: (level, message, containsPii) => {	
    //             if (containsPii) {		
    //                 return;		
    //             }		
    //             switch (level) {		
    //                 case LogLevel.Error:		
    //                     console.error(message);		
    //                     return;		
    //                 case LogLevel.Info:		
    //                     console.info(message);		
    //                     return;		
    //                 case LogLevel.Verbose:		
    //                     console.debug(message);		
    //                     return;		
    //                 case LogLevel.Warning:		
    //                     console.warn(message);		
    //                     return;
    //                 default:
    //                     return;
    //             }	
    //         }	
    //     }	
    // }
    
    
};


export const loginRequest = {
    scopes: ['openid','email','profile',msalConfig.auth.clientId]
};