
interface CADLandingProps {
  handleOptionsChange: any,
  selectedOptions:string[]

}

export const CADLanding = ({handleOptionsChange,selectedOptions}:CADLandingProps) => {
  const radio_button_array = [
    { option1: "Yes", option2: "No", description: "Is your test weight anything other than 3 ft. outside diameter?", for: "For All Mobiles Cranes:" },
    { option1: "Yes", option2: "No", description: "Does your crane have rear outrigger spread of greater than 24 ft.?", for: "For Boom Trucks:" },
    { option1: "Yes", option2: "No", description: "Does your crane have less than 42 ft. of main boom (jibs not permitted)?", for: "" },
    { option1: "Yes", option2: "No", description: "Does your crane operate only with a remote control?", for: "" },
    { option1: "Yes", option2: "No", description: "Does your crane have less than 30 ft. of main boom?", for: "For Carry Deck Cranes:" },
    { option1: "Yes", option2: "No", description: "Does your crane have less than 50 ft. of main boom?", for: "For Telescopic Boom Cranes (Carry Deck exempt):" }
  ];
  const handleOptionChange = (index: number, selectedOption: string) => {
    handleOptionsChange(index,selectedOption);
  };

  const getTitleFromIndex = (ind: number) => {
    return radio_button_array[ind].for===""?null:radio_button_array[ind].for
  };

  return (

    


    <div>
      {radio_button_array.map((record, index) => (

        <>
        <div key={index}>

     
        {getTitleFromIndex(index) && (
          <div   className={'cco_text cco_text_11'}>
            <div className={'cco_text_inner'}>
              <h3>{getTitleFromIndex(index)}</h3>
            </div>
          </div>
        )}

          <div  className="radio-button-item">

            <p className="cco_text_6">{record.description}</p>
            <div className="radio-buttons">
              <label className={'radio-buttons-yes'}>
                <input
                  type="radio"
                  name={`option${index}`}
                  value="Yes"
                  checked={selectedOptions[index] === "Yes"}
                  onChange={() => handleOptionChange(index, "Yes")}
                  style={{ marginRight: "5px" }}
                  className="radio-button" />
                Yes
              </label>
              <label className={'radio-buttons-no'}>
                <input
                  type="radio"
                  name={`option${index}`}
                  value="No"
                  checked={selectedOptions[index] === "No"}
                  onChange={() => handleOptionChange(index, "No")}
                  style={{ marginRight: "5px" }}
                  className="radio-button" />
                No
              </label>
            </div>
          </div>
          </div>
          </>
      ))}
    </div>
  );
};