import { useCredential } from "../../hooks/useCredential";
import React, { useState } from "react";
import { BlockUI, Loader } from "../../components/Loader";
import { HostedForm } from "react-acceptjs";
import { useCreateCredentials } from "../../hooks/useCreateCredentials";
import { createCredential, workshopData } from "../../types/credential";
import { ErrorMessage, Field, Formik } from "formik";
import { createcredentialSchema } from "../../schemas/credential";
import InputMask from "react-input-mask";
import { useAuthData } from "../../hooks/useAuthData";
import { usePrerequisiteCredential } from "../../hooks/usePrerequisiteCredential";
import { getContactId, utcToLocalDateTime } from "../../tools/utils";
import { Link, useLocation, useParams } from "react-router-dom";
import { usePrerequisiteTree } from "../../hooks/usePrerequisiteTree";
import { AvailableCredential } from "../../types/allcredential";

export const Credential = () => {
  /* eslint-disable no-restricted-globals */
  /* eslint-disable no-unused-expressions */

  const { id } = useLocation().state;
  const contactId = getContactId();
  const authQuery = useAuthData("contact-credential");
  const AuthData = authQuery.data?.data.Data;
  const cCredentials = useCreateCredentials();
  const credentials = useCredential(id);
  const preRequisiteQuery = usePrerequisiteCredential(id);
  const preRequisiteData = preRequisiteQuery.data?.data?.Data;

  const preRequisiteTreeQuery = usePrerequisiteTree(id);
  const preRequisiteTreeData = preRequisiteTreeQuery.data?.data?.Data;
  const preRequisiteTreeDataReversed = preRequisiteTreeData?.reverse();
  const userCredentials = credentials.data?.data?.Data || {} as AvailableCredential;
  const credentialObj = userCredentials;

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [checkout, setcheckout] = useState(false);
  const [workshopId, setWorkshops] = useState(null);
  const [isCreditInfo, setCreditInfo] = useState(false);
  const [creditData, setCreditData] = useState({
    LastFour: "",
    dataDescriptor: "",
    dataValue: "",
    CardNumber: "",
  });
  let CreateCredentialsObj: createCredential = {
    ContactId: contactId,
    CredentialId: id,
    WorkShopId: workshopId,
    Payment: {
      Amount: 0,
      AuthNetTransactionID: "",
      CreditCardType: "",
      LastFour: "",
      Paymenttype: "Credit Card",
      PaymentData: {
        dataDescriptor: "",
        dataValue: "",
        Address: "",
        City: "",
        FirstName: "",
        LastName: "",
        State: "",
        Zip: "",
        BillToEmail: "",
        BillToName: "",
        BillingPhone: "",
        BillingCountry: "",
      },
    },
  };
  const initialValues = {
    Address: "",
    BillToEmail: "",
    City: "",
    FirstName: "",
    LastName: "",
    Zip: "",
    State: "",
    BillingPhone: "",
    BillingCountry: "",
  };

  const handleSubmits = (response: any) => {
    if (response.messages.message[0].code === "I_WC_01") {
      setCreditInfo(true);
      setCreditData({
        LastFour: response.encryptedCardData.cardNumber.replaceAll("X", ""),
        dataDescriptor: response.opaqueData.dataDescriptor,
        dataValue: response.opaqueData.dataValue,
        CardNumber: response.encryptedCardData.cardNumber,
      });
    }
  };

  const handleTermsAndConditionsChange = (event: any) => {
    setTermsAndConditions(event.target.checked);
  };
  const processedtocheckout = (value: boolean) => {
    setcheckout(value);
  };
  function onWorkshopChanged(value: any) {
    setWorkshops(value);
  }

  function handleZeroFeeCredential() {
    const CreateCredentialsObj = {
      ContactId: contactId,
      CredentialId: id,
      WorkShopId: workshopId,
      Payment: null,
    };
    cCredentials
      .mutateAsync(CreateCredentialsObj)
      .then((res) => {
        if (res?.data?.ApiCode === 200 && res.success === true) {
          window.location.href = "/testing-personnel/";
        }
      })
      .finally(() => {})
      .catch((err) => {
        console.log(err);
      });
  }

  const isValidated = (!termsAndConditions) || (credentialObj.WorkShopsData.length > 0 && !workshopId);


  const reset = () => {
    preRequisiteQuery.refetch();
    credentials.refetch();
  };

  return (
    <>
      <div id="main-content">
        <div className="cco_content_area cco_content_area_0">
          <article className="react-container">
            <div className="cco_section cco_section_0">
              <div className="cco_row cco_row_8">
                <div className="cco_col">
                  <div className="cco_text cco_text_11">
                    <div className="cco_text_inner">
                      <strong>
                        {/* <a href="/testing-personnel">Testing Personnel</a> */}
                        <Link
                          to={"/testing-personnel"}
                          onClick={() => {
                            reset();
                          }}
                        >
                          Testing Personnel
                        </Link>
                      </strong>{" "}
                      {">"} {credentialObj && credentialObj?.CredentialName || ""}
                    </div>
                  </div>
                </div>
              </div>

              {!checkout && (
                <>
                  <div className="cco_row cco_row_7">
                    <div className="cco_col cco_col_6">
                      <div className="cco_text cco_text_10">
                        <div className="cco_text_inner">
                          <h2>
                            {(credentialObj.myCCOLabel && credentialObj.myCCOLabel + " Journey Status") ||
                              ""}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cco_row cco_row_3">
                    <div className="cco_col">
                      {preRequisiteTreeQuery.isLoading ? (
                        <Loader />
                      ) : preRequisiteTreeQuery.error ? (
                        <p>{preRequisiteTreeQuery.error.message}</p>
                      ) : preRequisiteTreeDataReversed &&
                        preRequisiteTreeDataReversed.length > 0 ? (
                        <div
                          className="cco_group cco_group_2"
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {preRequisiteTreeDataReversed.map(
                            (credential, index) => (
                              <>
                                <div
                                  className="cco_group_item"
                                  key={index}
                                  style={{ marginTop: "10px" }}
                                >
                                  {/* <a
                                    href={
                                      credential.ContactCredentialId === null &&
                                      credential.PreReqCredentialId
                                        ? `/credential/${credential.PreReqCredentialId}`
                                        : `/my-credential/${credential.ContactCredentialId}`
                                    }
                                    style={{ color: "#666" }}
                                  > */}
                                   <Link
  to={
    credential.ContactCredentialId === null && credential.PreReqCredentialId
      ? `/credential/${credential.PreReqCredentialId}`
      : `/my-credential/${credential.ContactCredentialId}`
  }
  state={
    credential.ContactCredentialId === null && credential.PreReqCredentialId
      ? { id: credential.PreReqCredentialId }
      : { id: credential.ContactCredentialId }
  }
  style={{ color: "#666" }}
>

                                    
                                    
                                    <div
                                      className={`cco_card cco_card_myCredential ${
                                        credential.Status === "Approved"
                                          ? "purchased-card"
                                          : credential.Status === "Pending"
                                          ? "pending-card"
                                          : "notcompleted-card"
                                      }`}
                                    >
                                      <div className="cco_card_body">
                                        <div
                                          className="cco_card_main"
                                          style={{
                                            width: "100%",
                                            padding: "10px",
                                          }}
                                        >
                                          <div className="cco_text cco_text_myCredential">
                                            <div className="cco_text_inner">
                                              <h3>
                                                {credential?.PreRequisiteName}
                                              </h3>
                                              <div>
                                                Status:{" "}
                                                {credential.Status ||
                                                  "Not Purchased"}
                                              </div>
                                              {/* <hr style={{color:'#ffffff'}} /> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </Link>
                                  {/* </a> */}
                                </div>
                                <div className="desktop-arrow"></div>
                                <div className="mobile-arrow"></div>
                              </>
                            )
                          )}

                          <div
                            className="cco_group_item"
                            style={{ marginTop: "10px" }}
                          >
                            <a href={"javascript:void(0)"} style={{ color: "#666" }}>
                              <div
                                className={`cco_card cco_card_myCredential`}
                                style={{
                                  backgroundColor: "#c67b05",
                                  color: "#ffffff",
                                }}
                              >
                                <div className="cco_card_body">
                                  <div
                                    className="cco_card_main"
                                    style={{ width: "100%", padding: "10px" }}
                                  >
                                    <div className="cco_text cco_text_myCredential">
                                      <div className="cco_text_inner">
                                        <h3 style={{ color: "#ffffff" }}>
                                          {credentialObj.myCCOLabel}{" "}
                                        </h3>
                                        <div>Status: {"Not Purchased"}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      ) : (
                        <p>
                          {" "}
                          There are no pre-requisites for{" "}
                          {credentialObj && credentialObj.CredentialName}{" "}
                          credential.
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}

              {checkout && termsAndConditions && (
                <>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={createcredentialSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      if (isCreditInfo == true) {
                        CreateCredentialsObj = {
                          ContactId: contactId,
                          CredentialId: id,
                          WorkShopId: workshopId,
                          Payment: {
                            Amount: credentialObj.CredentialFee.Price,
                            AuthNetTransactionID: "",
                            CreditCardType: "",
                            LastFour: creditData.LastFour,
                            Paymenttype: "Credit Card",
                            PaymentData: {
                              dataDescriptor: creditData.dataDescriptor,
                              dataValue: creditData.dataValue,
                              Address: values.Address,
                              City: values.City,
                              FirstName: values.FirstName,
                              LastName: values.LastName,
                              State: values.State,
                              Zip: values.Zip,
                              BillToEmail: values.BillToEmail,
                              BillToName:
                                values.FirstName + " " + values.LastName,
                              BillingCountry: values.BillingCountry,
                              BillingPhone: values.BillingPhone,
                            },
                          },
                        };
                        cCredentials
                          .mutateAsync(CreateCredentialsObj)
                          .then((res) => {
                            if (
                              res?.data?.ApiCode === 200 &&
                              res.success === true
                            ) {
                              setSubmitting(false);
                              window.location.href = "/testing-personnel/";
                            }
                          })
                          .finally(() => {
                            setSubmitting(false);
                          })
                          .catch((err) => {
                            console.log(err);
                            setSubmitting(false);
                          });
                      } else {
                        setSubmitting(false);
                        return;
                      }
                    }}
                  >
                    {({
                      values,
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      handleReset,
                      isValid,
                      dirty,
                    }) => (
                      <form
                        onReset={handleReset}
                        onSubmit={(e) => {
                          if (isSubmitting) {
                            e.preventDefault();
                          } else {
                            handleSubmit(e);
                          }
                        }}
                        className="cco_form"
                      >
                        <div className="cco_row">
                          <div className="cco_col">
                            <>
                              <div className="cco_form_row">
                                <div className="cco_form_col">
                                  <h2 className="cco_form_heading">
                                    Billing Information
                                  </h2>

                                  <div className="cco_form_wrap">
                                    <div className="cco_form_row">
                                      <div
                                        className="cco_form_col"
                                        style={{ marginBottom: "10px" }}
                                      >
                                        <div className="cco_form_wrap">
                                          <div
                                            className="cco_blurb cco_blurb_4"
                                            style={{ alignItems: "center" }}
                                          >
                                            {isCreditInfo && (
                                              <>
                                                <div className="cco_blurb_main">
                                                  {" "}
                                                  Credit Card Number
                                                </div>
                                                <div className="cco_blurb_aside">
                                                  {creditData?.CardNumber || ""}
                                                </div>
                                              </>
                                            )}
                                            {!isCreditInfo && (
                                              <>
                                                <HostedForm
                                                  buttonText={
                                                    "Click here to fill Credit Card Information"
                                                  }
                                                  formButtonText={"Continue"}
                                                  containerClassName={
                                                    "cco_menu_item"
                                                  }
                                                  buttonStyle={{
                                                    backgroundColor: "#c67b05",
                                                    color: "#ffffff",
                                                    fontFamily: '"DMSans Bold"',
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    lineHeight: "1",
                                                    textAlign: "center",
                                                    display: "inline-flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    maxWidth: "100%",
                                                    width: "100%",
                                                    padding: "10px",
                                                    border: "none",
                                                    opacity: isValidated
                                                      ? ".5"
                                                      : "1",
                                                    cursor: isValidated
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  }}
                                                  containerStyle={{
                                                    backgroundColor: "#c67b05",
                                                    color: "#ffffff",
                                                    fontFamily: '"DMSans Bold"',
                                                    fontSize: "18px",
                                                    fontWeight: "700",
                                                    lineHeight: "1",
                                                    textAlign: "center",
                                                    display: "inline-flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    maxWidth: "100%",
                                                    minHeight: "42px",
                                                    padding: "0px 0px",
                                                    border: "2px solid #c67b05",
                                                    borderRadius: "21px",
                                                    cursor: isValidated
                                                      ? "not-allowed"
                                                      : "pointer",
                                                  }}
                                                  formHeaderText={
                                                    credentialObj.CredentialName +
                                                    " Payment"
                                                  }
                                                  authData={{
                                                    apiLoginID:
                                                      AuthData?.ApiLoginID ||
                                                      "",
                                                    clientKey:
                                                      AuthData?.ClientKey || "",
                                                  }}
                                                  environment={
                                                    AuthData?.IsSandbox
                                                      ? "SANDBOX"
                                                      : "PRODUCTION"
                                                  }
                                                  onSubmit={handleSubmits}
                                                  billingAddressOptions={{
                                                    show: false,
                                                    required: false,
                                                  }}
                                                  errorTextStyle={{
                                                    fontSize: "12px",
                                                  }}
                                                />
                                                {!isCreditInfo &&
                                                  isSubmitting && (
                                                    <p
                                                      style={{
                                                        color: "red",
                                                        textAlign: "center",
                                                        padding: "10px",
                                                      }}
                                                    >
                                                      Please fill credit card
                                                      information!
                                                    </p>
                                                  )}
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <ul className="cco_form_fieldset">
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoFullName">
                                          First Name{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="FirstName"
                                        />
                                        <ErrorMessage
                                          name={"FirstName"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoFullName">
                                          Last Name{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="LastName"
                                        />
                                        <ErrorMessage
                                          name={"LastName"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoEmail">
                                          Email{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="BillToEmail"
                                        />
                                        <ErrorMessage
                                          name={"BillToEmail"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoPhoneBilling">
                                          Phone{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>

                                        <InputMask
                                          type={"tel"}
                                          id={"ccoPhoneBilling"}
                                          mask="+1 (999) 999-9999"
                                          value={values.BillingPhone}
                                          onChange={handleChange(
                                            "BillingPhone"
                                          )}
                                          onBlur={handleBlur("BillingPhone")}
                                        />

                                        <ErrorMessage
                                          component={"div"}
                                          name={"BillingPhone"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoBillingCountry">
                                          Country{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoBillingCountry"
                                          name="BillingCountry"
                                        />
                                        <ErrorMessage
                                          name={"BillingCountry"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>

                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                        <label htmlFor="ccoFullName">
                                          Address{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="Address"
                                        />
                                        <ErrorMessage
                                          name={"Address"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                        <label htmlFor="ccoCity">
                                          City{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="City"
                                        />
                                        <ErrorMessage
                                          name={"City"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>

                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                        <label htmlFor="ccoState">
                                          State{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="State"
                                        />
                                        <ErrorMessage
                                          name={"State"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                      <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                        <label htmlFor="ccoFullName">
                                          Zip{" "}
                                          <span style={{ color: "#db3a3a" }}>
                                            *
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          id="ccoCity"
                                          name="Zip"
                                        />
                                        <ErrorMessage
                                          name={"Zip"}
                                          component={"div"}
                                          className="cco_form_message cco_form_message_error"
                                        />
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="cco_form_row">
                                <div className="cco_form_col">
                                  {!isSubmitting && (
                                    <>
                                      {cCredentials.data?.success && (
                                        <div
                                          className="cco_form_message cco_form_message_success"
                                          style={{
                                            fontSize: 16,
                                            marginBottom: 20,
                                          }}
                                        >
                                          <strong>
                                            Request submited successfully.
                                          </strong>
                                        </div>
                                      )}

                                      {cCredentials.error && (
                                        <div
                                          className="cco_form_message cco_form_message_error"
                                          style={{
                                            marginBottom: 20,
                                          }}
                                        >
                                          {
                                            (cCredentials.error as Error)
                                              .message
                                          }
                                        </div>
                                      )}
                                    </>
                                  )}
                                  <ul className="cco_menu cco_menu_7">
                                    <li className="cco_menu_item">
                                      <button
                                        className="cco_form_submit"
                                        disabled={
                                          !(isValid && dirty) && !isCreditInfo
                                        }
                                        type={"submit"}
                                        style={{
                                          opacity:
                                            isValid && dirty && isCreditInfo
                                              ? "1"
                                              : ".5",
                                          cursor:
                                            isValid && dirty && isCreditInfo
                                              ? "pointer"
                                              : "not-allowed",
                                        }}
                                      >
                                        {cCredentials.isLoading ||
                                        (cCredentials.isSuccess && isCreditInfo)
                                          ? "Processing"
                                          : "Pay $" +
                                            credentialObj.CredentialFee.Price}
                                      </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </>
              )}

              {!checkout && (
                <>
                  <div className="cco_row ">
                    <div className="cco_col">
                      <div className="cco_text cco_text_0">
                        <div className="cco_text_inner">
                          <strong>
                            {credentialObj?.CredentialName || ""} Credential
                            Information
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cco_row">
                    <div className="cco_col">
                      {credentials.isLoading ? (
                        <Loader />
                      ) : credentials.error ? (
                        <p>{credentials.error.message}</p>
                      ) : (
                        <div className="cco_card cco_card_2">
                          <div className="cco_card_body"></div>
                          {/* Credential Description */}
                          {credentialObj.ProductDetail ? (
                            <div className="cco_card_footer">
                              <div className="cco_text">
                                <div
                                  className="cco_text_inner"
                                  dangerouslySetInnerHTML={{
                                    __html: credentialObj?.ProductDetail || "",
                                  }}
                                ></div>
                              </div>
                            </div>
                          ) : (
                            <p>Credential information is empty</p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* WorkShop Lists */}
              {credentialObj.Workshops == true && !checkout && (
                <>
                  <div className="cco_row cco_row_2">
                    <div className="cco_col">
                      <div className="cco_text cco_text_2">
                        <div className="cco_text_inner">
                          <h2>Available Workshops</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cco_row cco_row_3">
                    <div className="cco_col">
                      {credentials.isLoading ? (
                        <Loader />
                      ) : credentials.error ? (
                        <p>{credentials.error.message}</p>
                      ) : credentialObj.WorkShopsData.length > 0 ? (
                        <ul className="cco_accordion">
                          <div className="cco_text cco_text_8">
                            <p>You must select one workshop to proceed.</p>
                          </div>
                          {credentialObj.WorkShopsData.map(
                            (WorkShop: workshopData, index: number) => {
                              return (
                                <li
                                  className="cco_accordion_item active"
                                  key={index}
                                  style={{
                                    border:
                                      workshopId === WorkShop.Id
                                        ? "2px solid #c67b1d"
                                        : "",
                                  }}
                                >
                                  <div className="cco_accordion_item_body">
                                    <table className="cco_table">
                                      <tbody className="cco_table_body">
                                        <tr className="cco_table_row">
                                          <td
                                            className="cco_table"
                                            style={{
                                              paddingLeft: "20px",
                                              paddingTop: "25px",
                                            }}
                                          >
                                            <div className="cco_form_fieldset_item">
                                              <label className="cco_form_checkbox_0">
                                                <input
                                                  id={WorkShop.Id}
                                                  type="radio"
                                                  name={"workshop" + index}
                                                  onChange={(e) =>
                                                    onWorkshopChanged(
                                                      e.target.value
                                                    )
                                                  }
                                                  value={WorkShop.Id}
                                                  checked={
                                                    workshopId === WorkShop.Id
                                                  }
                                                  className="cco_form_checkbox_input"
                                                />
                                                <span
                                                  className="cco_form_checkbox_checkmark"
                                                  style={{
                                                    border:
                                                      workshopId === WorkShop.Id
                                                        ? "2px solid #c67b1d"
                                                        : "1px solid #c67b1d",
                                                  }}
                                                ></span>
                                              </label>
                                            </div>
                                          </td>
                                          <td className="cco_table_cell cco_table_cell_large">
                                            <div className="cco_blurb cco_blurb_0">
                                              <div className="cco_blurb_label">
                                                Start Date/Time*
                                              </div>
                                              <div className="cco_blurb_value">
                                                <strong>
                                                  {utcToLocalDateTime(
                                                    WorkShop.StartDateTime
                                                  ) || "-"}
                                                </strong>
                                              </div>
                                            </div>
                                            <div className="cco_blurb cco_blurb_0">
                                              <div className="cco_blurb_label">
                                                End Date/Time*
                                              </div>
                                              <div className="cco_blurb_value">
                                                <strong>
                                                  {utcToLocalDateTime(
                                                    WorkShop.EndDateTime
                                                  ) || "-"}
                                                </strong>
                                              </div>
                                            </div>
                                            <div className="cco_blurb cco_blurb_0">
                                              <div className="cco_blurb_label">
                                                Location
                                              </div>
                                              <div className="cco_blurb_value">
                                                <strong>
                                                  {WorkShop.LocationName || "-"}
                                                </strong>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="cco_table_cell">
                                            <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                              <div className="cco_blurb_label">
                                                Available Seats
                                              </div>
                                              <div className="cco_blurb_value">
                                                <strong>
                                                  {WorkShop.AvailableSeats ||
                                                    "-"}
                                                </strong>
                                              </div>
                                            </div>
                                            <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                              <div className="cco_blurb_label">
                                                Application Deadline
                                              </div>
                                              <div className="cco_blurb_value">
                                                <strong>
                                                  {utcToLocalDateTime(
                                                    WorkShop.ApplicationDeadline
                                                  ) || "-"}
                                                </strong>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      ) : (
                        <p style={{ color: "red", fontWeight: "bold" }}>
                          Sorry, there are currently no available workshops. You
                          are not able to continue until a workshop becomes
                          available. If you'd like to be added to the waitlist,
                          please email:
                          <a href="mailto:proctorworkshops@nccco.org">
                            proctorworkshops@nccco.org
                          </a>
                          . Provide your name, CCO ID, and indicate which
                          workshop you are interested in attending. We will
                          notify you when additional workshops are added.
                        </p>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* {cCredentials.isLoading || cCredentials.isSuccess ?'' : ( */}

              {!checkout && (
                <>
                  <div className="cco_row cco_row_2">
                    <div className="cco_col">
                      <div className="cco_text cco_text_0">
                        <div className="cco_text_inner">
                          <strong>Agreement</strong>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cco_row cco_row_9">
                    {credentials.isLoading ? (
                      <Loader />
                    ) : credentials.error ? (
                      <p>{credentials.error.message}</p>
                    ) : (
                      <div className="cco_col">
                        <div className="cco_text cco_text_14">
                          <div
                            className="cco_text_inner"
                            dangerouslySetInnerHTML={{
                              __html: credentialObj?.Agreement || "",
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>

                  {(preRequisiteData?.Status === 'Approved' || preRequisiteData === null) &&
                      
                  <div className="cco_form_row">
                    <div className="cco_form_col">
                      {credentials.isLoading ? (
                        <Loader />
                      ) : credentials.error ? (
                        <p>{credentials.error.message}</p>
                      ) : (
                        <ul className="cco_menu cco_menu_4">
                          <li
                            className={"cco_menu_item cco_menu_item_checkbox"}
                          >
                            <label
                              className={
                                "cco_form_checkbox cco_form_checkbox_0"
                              }
                            >
                              <input
                                disabled={
                                  !credentialObj.WorkShopsData.length &&
                                  credentialObj.Workshops
                                }
                                name="termsAndConditions"
                                id="ccoTermsAndConditions"
                                type="checkbox"
                                className={"cco_form_checkbox_input"}
                                value="termsAndConditions"
                                onChange={handleTermsAndConditionsChange}
                              />
                              <span
                                className={"cco_form_checkbox_checkmark"}
                                style={{
                                  border: termsAndConditions
                                    ? "2px solid #c67b1d"
                                    : "1px solid #c67b1d",
                                  cursor:
                                    !credentialObj.WorkShopsData.length &&
                                    credentialObj.Workshops
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              ></span>
                              <p style={{ fontSize: "12px" }}>
                                I understand and agree to this Agreement. I
                                further understand and agree that a payment to
                                register or enroll in any testing personnel
                                training (such as a course or a workshop) cannot
                                be refunded once my request and payment are
                                submitted to CCO. In addition, I understand that
                                any such registration cannot be cancelled or
                                transferred. By submitting a payment, I agree to
                                CCO's{" "}
                                <a
                                  style={{
                                    color: "#c67b05",
                                    padding: "0px",
                                    alignItems: "normal",
                                  }}
                                  href="https://www.nccco.org/nccco/about-nccco/policies/financial-terms-conditions"
                                >
                                  Financial Terms and Conditions
                                </a>
                                .
                              </p>
                            </label>
                          </li>
                          {!credentialObj.WorkShopsData.length &&
                          credentialObj.Workshops ? (
                            <li
                              className={`cco_menu_item cco_menu_item_btn cco_menu_item_btn_disabled`}
                            >
                              <a
                                href={`javascript:void(0)`}
                                onClick={event?.preventDefault}
                              >
                                Proceed to checkout
                              </a>
                            </li>
                          ) : (
                            <li
                              className={`cco_menu_item cco_menu_item_btn ${
                                isValidated ? "cco_menu_item_btn_disabled" : ""
                              }`}
                            >
                              {/* <a href={(!termsAndConditions) || credentialObj.WorkShopsData.length > 0 && !workshopId ? `javascript:void(0)` : `javascript:void(0)`} onClick={() => { isValidated ? event?.preventDefault : credentialObj.CredentialFee.Price === 0 ? handleZeroFeeCredential() : processedtocheckout(true); }}>{credentialObj.CredentialFee.Price === 0 ? 'Submit' : "Proceed to checkout"}</a> */}
                              <a
                                href={
                                  !termsAndConditions ||
                                  (credentialObj.WorkShopsData.length > 0 &&
                                    !workshopId)
                                    ? "javascript:void(0)"
                                    : "javascript:void(0)"
                                }
                                onClick={() => {
                                  if (isValidated) {
                                    event?.preventDefault();
                                  } else if (
                                    credentialObj.CredentialFee.Price === 0
                                  ) {
                                    handleZeroFeeCredential();
                                  } else {
                                    processedtocheckout(true);
                                  }
                                }}
                              >
                                {credentialObj.CredentialFee.Price === 0
                                  ? "Submit"
                                  : "Proceed to checkout"}
                              </a>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </div>
}
                </>
              )}
            </div>
          </article>
        </div>
      </div>

      <BlockUI
        title="Please wait while payment is processed. Do not refresh page or click back button."
        blocking={cCredentials.isLoading || cCredentials.isSuccess}
      />
    </>
  );
};
