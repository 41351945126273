import * as yup from 'yup';

const getRequiredMessage = (field: string) => `The ${field} field is required`;
export const createcredentialSchema = yup.object().shape({
    Zip: yup.string().trim().required(getRequiredMessage('Zip')),
    City: yup.string().trim().required(getRequiredMessage('City')),
    FirstName: yup.string().trim().required(getRequiredMessage('First Name')),
    LastName: yup.string().trim().required(getRequiredMessage('Last Name')),
    Address: yup.string().trim().required(getRequiredMessage('Address')),
    State: yup.string().trim().required(getRequiredMessage('State')),
    BillToEmail: yup.string().trim().email('Invalid email').required(getRequiredMessage('Email'))  ,
    BillingPhone: yup.string().trim().required(getRequiredMessage('phone')),
    BillingCountry: yup.string().trim().required(getRequiredMessage('country')),
})
