import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/product';

export const useLocationProduct = (ProductName?: string | null) => {
    return useQuery<RootObject, Error>(['productname',ProductName], async () => {
        const jsonResponse = await request({
            endpoint: 'products/get-byname',
            data: {
                productName: ProductName,
            },
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};
