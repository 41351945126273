import React from 'react';

interface IBannerProps{

    showBanner:boolean,
    message:string
}
export const PhotoNotificationBanner = ({showBanner,message}:IBannerProps) => {
        if(showBanner){
            const sidebarElement = document.getElementById("ccoSidebar") as HTMLElement;
            sidebarElement.style.paddingTop =showBanner?"95px":'70px';
        }

    return (
        showBanner ? (
            <div className="custom-overlay">
                <div className="cco_row" style={{ marginBottom: '20px' }}>
                    <div className="cco_col">
                        <div className="cco_text cco_text_danger">
                            <div className="cco_text_inner">
                                <p>{message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (null)

    );
};
