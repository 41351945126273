import React from 'react';
import { credentialsButtons, customURLs, getToken, notAllowedButtons, NullBadgeUrl, utcToLocalDateTime } from "../../tools/utils";
import { Loader } from '../../components/Loader';
import { useMyCredential } from '../../hooks/useMyCredential';
import { Link, useLocation } from 'react-router-dom';
export const MyCredential = () => {
    const { id } = useLocation().state;
    const { REACT_APP_BASE_URL } = process.env;
    const myCredentialQuery = useMyCredential(id);
    const myCredentialData = myCredentialQuery.data?.data?.Data;
    const token = getToken()
    const credentialButtons = credentialsButtons.find(x => x.CredentialsName === myCredentialData?.EarnedCredential.Name)?.MyCredentials || [];
    const handleClick = (event) => {
        const form = document.getElementById('myForm') as HTMLFormElement;
        form.submit();
    }
    return (

        <div id="main-content">
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">

                    <div className="cco_section cco_section_0">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong>
                                            <a href="/testing-personnel">Testing Personnel</a>
                                        </strong>{' '}
                                        {'>'} {myCredentialData?.EarnedCredential?.Name || ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cco_row">
                            <div className="cco_col">
                                {myCredentialQuery.isLoading ? (<Loader />) : myCredentialQuery.error ? (<p>{myCredentialQuery.error.message}</p>) : (
                                    <div className="cco_card cco_card_2" style={{ paddingLeft: "20px" }}>
                                        <div className="cco_card_body">
                                            <div className="cco_card_aside">
                                                <div className="cco_img">
                                                    <span className="cco_img_wrap">
                                                        {/* <img style={{border:myCredentialData?.EarnedCredential.StatusReason==='Approved'?'':"1px solid rgb(0 0 0 / 5%)",borderRadius:myCredentialData?.EarnedCredential.StatusReason==='Approved'?'':"23px"}} alt={myCredentialData?.EarnedCredential?.Name} src={myCredentialData?.EarnedCredential.StatusReason==='Approved'?myCredentialData?.EarnedCredential?.BadgeUrl:NullBadgeUrl} /> */}
                                                        <img style={{ border: myCredentialData?.EarnedCredential.StatusReason === 'Approved' && myCredentialData?.EarnedCredential.BadgeUrl != null ? '' : "1px solid rgb(0 0 0 / 5%)", borderRadius: myCredentialData?.EarnedCredential.StatusReason === 'Approved' && myCredentialData?.EarnedCredential.BadgeUrl != null ? '' : "23px" }} alt={myCredentialData?.EarnedCredential.Name} src={myCredentialData?.EarnedCredential.StatusReason === 'Approved' && myCredentialData?.EarnedCredential.BadgeUrl != null ? myCredentialData?.EarnedCredential.BadgeUrl : NullBadgeUrl} />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="cco_card_main" style={{ padding: '10px' }}>
                                                <div className={'cco_text cco_text_9'}>
                                                    <div className={'cco_text_inner'}>
                                                        <h3>{myCredentialData?.EarnedCredential?.Name || ''}</h3>
                                                        <div>Status: {myCredentialData?.EarnedCredential.StatusReason}</div>
                                                        {myCredentialData?.EarnedCredential?.ExpiryDate && <div>Expires: {myCredentialData?.EarnedCredential.ExpiryDate.split('T')[0] === '0001-01-01' ? 'Not Available' : myCredentialData?.EarnedCredential.ExpiryDate.split('T')[0]}</div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* WorkShop Lists */}
                        {myCredentialData?.EarnedCredential.WorkShops == true && (
                            <><div className="cco_row cco_row_2">
                                <div className="cco_col">
                                    <div className="cco_text cco_text_2">
                                        <div className="cco_text_inner">
                                            <h2>Workshops</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="cco_row cco_row_3">
                                    <div className="cco_col">
                                        {myCredentialQuery.isLoading ? (<Loader />) : myCredentialQuery.error ? (<p>{myCredentialQuery.error.message}</p>) : (
                                            myCredentialData?.EarnedCredential.workshopApplications.length > 0 ? (
                                                <ul className="cco_accordion">
                                                    {myCredentialData?.EarnedCredential.workshopApplications.map((WorkShop, index: number) => {
                                                        return (
                                                            <li className="cco_accordion_item active" key={index}>
                                                                <div className="cco_accordion_item_body">
                                                                    <table className="cco_table">
                                                                        <tbody className="cco_table_body">
                                                                            <tr className="cco_table_row">
                                                                                <td className="cco_table_cell cco_table_cell_large">
                                                                                    <div className="cco_blurb cco_blurb_0">
                                                                                        <div className="cco_blurb_label">Start Date/Time</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            {/* <strong title='EST DateTime'>{moment(WorkShop.StartDateTime).tz('America/New_York').format("MM-DD-YYYY hh:mmA") || '-'}</strong> */}
                                                                                            <strong>{utcToLocalDateTime(WorkShop.StartDateTime) || '-'}</strong>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="cco_blurb cco_blurb_0">
                                                                                        <div className="cco_blurb_label">Name</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            <strong>{WorkShop.WorkShopName || '-'}</strong>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                                        <div className="cco_blurb_label">LaunchLink</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            {/* <strong>{WorkShop.LaunchLink || '-'}</strong> */}
                                                                                            <a target={WorkShop.LaunchLink ? '_blank' : ''} href={WorkShop.LaunchLink ? WorkShop.LaunchLink.toUpperCase().startsWith('W') ? 'https://' + WorkShop.LaunchLink : WorkShop.LaunchLink : 'javascript:void(0)'}>{WorkShop.LaunchLink || '-'}</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="cco_table_cell">
                                                                                    <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                                                                        <div className="cco_blurb_label">Status</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            <strong>{WorkShop.Status || '-'}</strong>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="cco_blurb cco_blurb_0">
                                                                                        <div className="cco_blurb_label">Location</div>
                                                                                        <div className="cco_blurb_value">
                                                                                            <strong>{WorkShop.LocationName || '-'}</strong>
                                                                                        </div>
                                                                                    </div>


                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </li>
                                                        )


                                                    })}
                                                </ul>
                                            ) : (
                                                <p>No workshop applications available</p>
                                            )
                                        )}
                                    </div>
                                </div></>
                        )}

                        <div className="cco_row cco_row_2">
                            <div className="cco_col">
                                <div className="cco_text cco_text_2">
                                    <div className="cco_text_inner">
                                        <h2>{myCredentialData?.EarnedCredential?.Name || ''} Description</h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cco_row">
                            <div className="cco_col">
                                {myCredentialQuery.isLoading ? (<Loader />) : myCredentialQuery.error ? (<p>{myCredentialQuery.error.message}</p>) : (
                                    <div className="cco_card cco_card_2">

                                        <div className="cco_card_body">
                                        </div>
                                        {/* Credential Description */}
                                        {myCredentialData?.EarnedCredential.Description && (
                                            <div className="cco_card_footer">
                                                <div className="cco_text">
                                                    <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: myCredentialData?.EarnedCredential?.Description || '' }}></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>

                        </div>


                        {credentialButtons.length > 0 && (
                <div className="cco_row cco_row_1">
                    <div className="cco_col">
                        <ul className="cco_menu cco_menu_0">
                            {credentialButtons?.map((button: any, index: number) => {
                                // if (index > 0 && button?.button_text) {
                                    if (myCredentialData?.EarnedCredential.StatusReason === 'Pending' && button?.button_text === 'Open Online Learning Center') {
                                        return (
                                            <li className="cco_menu_item cco_menu_item_primary" key={index}>
                                                {/* <a style={{ maxWidth: "400px" }} href={button?.button_url || '#'}>{button?.button_text}</a> */}
                                                <form action={`${REACT_APP_BASE_URL}/courses-dashboard`} target="_blank" id='myForm' method="post">
                                                            <input type="hidden" name="state" value="https://trueb2c.live/wp-admin/" />
                                                            <input type="hidden" name="id_token" value={token} />
                                                            <a style={{ maxWidth: "400px" }} onClick={handleClick} href='#' >{button?.button_text}</a>

                                                        </form>
                                            
                                            </li>
                                        );
                                    }
                                    if (myCredentialData?.EarnedCredential.StatusReason === 'Approved') {
                                        return (
                                            <li className="cco_menu_item cco_menu_item_primary" key={index}>
                                                <a style={{ maxWidth:credentialButtons.length===1?"400px":''}} href={button?.button_url || '#'}>{button?.button_text}</a>
                                            </li>
                                            
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                            })}
                            {myCredentialData?.EarnedCredential.StatusReason === 'Approved' &&  !notAllowedButtons.includes(myCredentialData.EarnedCredential.Name) && (
                                <><li className="cco_menu_item cco_menu_item_primary">
                                    <a
                                        href={`${token && customURLs?.manageMyTestAdministrationsUrl?.button_url
                                            ? customURLs.manageMyTestAdministrationsUrl.button_url + '?id_token=' + token
                                            : '#'}`}
                                        target="_blank"
                                        rel="noreferrer">
                                        {customURLs?.manageMyTestAdministrationsUrl?.button_text || 'Button Text'}
                                    </a>
                                </li>
                                {myCredentialData.EarnedCredential.ResourcesURL &&
                                <li className="cco_menu_item cco_menu_item_primary" >
                                        <a href={myCredentialData.EarnedCredential.ResourcesURL}>{myCredentialData.EarnedCredential.myCCOLabel} Resources</a>
                                </li>
                                }
                                
                                    </>
                            )}
                        </ul>
                    </div>
                </div>
            )}
                    </div>
                </article>
            </div>
        </div>
    );
};
