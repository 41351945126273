import { useState } from 'react';
import classNames from 'classnames';
import type { UpcomingExam, ExamHistory } from '../../types/exams';

interface IAccordionProps {
    exam: UpcomingExam | ExamHistory;
}

export const Accordion = ({ exam }: IAccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <li className={classNames('cco_accordion_item', { active: isOpen })}>
            <div className="cco_accordion_item_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>{exam.ExamName || 'Exam Name'}</h3>
                    </div>
                </div>
                <button className="cco_accordion_item_toggle" onClick={() => setIsOpen(prev => !prev)}>
                    <span className="cco_accordion_item_toggle_text">View</span>
                </button>
            </div>
            <div className="cco_accordion_item_body">
                <div className="cco_accordion_item_body_inner">
                    <table className="cco_table">
                        <tbody className="cco_table_body">
                            <tr className="cco_table_row">
                                {exam.ApplicationType && (
                                    <td className="cco_table_cell">
                                        <div className="cco_blurb cco_blurb_0">
                                            <div className="cco_blurb_label">Modality</div>
                                            <div className="cco_blurb_value">
                                                <strong>{exam.ApplicationType}</strong>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                {exam.TestDate && (
                                    <td className="cco_table_cell">
                                        <div className="cco_blurb cco_blurb_0">
                                            <div className="cco_blurb_label">Test Date</div>
                                            <div className="cco_blurb_value">
                                                <strong>{exam.TestDate || 'Test Date'}</strong>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                {exam.TestAdminNumber && (
                                    <td className="cco_table_cell">
                                        <div className="cco_blurb cco_blurb_align_center cco_blurb_0">
                                            <div className="cco_blurb_label">Admin</div>
                                            <div className="cco_blurb_value">
                                                <strong>{exam.TestAdminNumber}</strong>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                {exam.Candauthcode && (
                                    <td className="cco_table_cell">
                                        <div className="cco_blurb cco_blurb_align_center cco_blurb_0">
                                            <div className="cco_blurb_label">Auth Code</div>
                                            <div className="cco_blurb_value">
                                                <strong>{exam.Candauthcode}</strong>
                                            </div>
                                        </div>
                                    </td>
                                )}
                                {exam.OverallStatus && (
                                    <td className="cco_table_cell">
                                        <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                            <div className="cco_blurb_label">Status</div>
                                            <div className="cco_blurb_value">
                                                <strong>{exam.OverallStatus}</strong>
                                            </div>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </li>
    );
};
