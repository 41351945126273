import type { RootObject } from '../types/pracexamtypes';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const usePracticalExamTypes = () => {
    return useQuery<RootObject, Error>(
        ['examTypes'],
        async () => {
            const jsonResponse = await request({
                endpoint: 'practicalsitetype/get',
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        }
      
    );
};
