import { CADSchema } from "../../schemas/customCAD";
import { ErrorMessage, Field, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Required } from "../../components/Required/Required";
import { IValuesCAD } from "./valuesTypes";
import { BillingInformation } from "./BillingInformation";
import { CADLanding } from "./CADLanding";
import { useLocationProduct } from "../../hooks/useLocationProduct";
import { LocationProductData } from "../../types/product";
import { useAuthData } from "../../hooks/useAuthData";
import { useCADMutationQuery } from "../../hooks/useCADMutationQuery";
import { useUpload } from "../../hooks/useUpload";
import { BlockUI, Loader } from "../../components/Loader";
import { useCadCraneTypes } from "../../hooks/useCADCraneTypes";
import { screenedValuesCAD } from "../../tools/constants";
import { createNewSortInstance } from "fast-sort";
import { useLocation } from "react-router-dom";

const naturalSort = createNewSortInstance({
    comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
});

const descCAD = "<p>Custom CADs are only supported for the mobile crane operator program (LAT, TLL, TSS). For more information about custom CAD requirements, please visit: https://www.nccco.org/nccco/news-center/archived-press-releases/news/2021/09/15/cco-simplifies- and-standardizes-mobile-crane-cads</p>";

export const CustomCAD = () => {
    const { eotId = null } = useLocation().state;
    let initialValues: IValuesCAD = {
        make: '',
        model: '',
        serialNumber: '',
        maxRelatedCapacity: undefined,
        boomLength: undefined,
        frontOutrigger: null,
        backOutrigger: null,
        ropeSizeType: null,
        allowableLine: '',
        diameterTestWeight: '',
        loadChart: '',
        craneType: "",
        isBoomTruck: 'default',
        workingArea: 'default',
        cranePosition: 'default',
        truckBedLength: undefined,
        linePullChart: '',
        rangeDiagram: '',
        BillToName: "",
        BillToEmail: "",
        LastFour: "",
        Amount: 0,
        AuthNetTransactionID: "",
        Paymenttype: "",
        CreditCardType: "",
        FirstName: "",
        LastName: "",
        Address: "",
        City: "",
        State: "",
        Zip: "",
        dataDescriptor: "",
        dataValue: "",
        CardNumber: "",
        BillingPhone: "",
        BillingCountry: ""
    }
    const productStandardQuery = useLocationProduct('CAD Expedited');
    const productRushQuery = useLocationProduct('CAD RUSH Expedited');
    const productStandardData = productStandardQuery.data?.data.Data || {} as LocationProductData;
    const productRushData = productRushQuery.data?.data.Data || {} as LocationProductData;
    const deliveryTypes = [productStandardData, productRushData]

    const authQuery = useAuthData('location');
    const AuthData = authQuery.data?.data.Data;
    const cadTypesQuery = useCadCraneTypes('Mobile Cranes');
    const cadTypesData = cadTypesQuery.data?.data?.Data;
    const cadSubTypesData = naturalSort(cadTypesData && cadTypesData[0]?.CADSubTypes || []).asc((cad) => cad?.Name);


    const [creditData, setCreditData] = useState({ LastFour: '', dataDescriptor: '', dataValue: '', CardNumber: '' });
    const [creditInfo, setCreditInfo] = useState(false);
    const [ccError, setCCError] = useState(false);
    const [showLoading, setLoading] = useState(false);
    const uploadFile = useUpload();


    const [craneType, setcraneType] = useState({ Name: '', CADSubTypeId: '' });
    const [ProductdeliveryType, setdeliveryType] = useState({ PriceLevelId: "", ProductId: "", ProductName: "", ProductPrice: 0 } as LocationProductData);
    const [isCraneSelected, setIsCraneSelected] = useState(false);
    const [isDeliverySelected, setIsDeliverySelected] = useState(false);
    const [buttonText, setbuttonText] = useState('Next');
    const authData = {
        apiLoginID: AuthData?.ApiLoginID || '',
        clientKey: AuthData?.ClientKey || '',
        IsSandbox: AuthData?.IsSandbox
    };

    function onCraneTypeChanged(value: any) {
        setcraneType(value)
    }

    function onDeliveryTypeChanged(value: LocationProductData) {
        setdeliveryType(value);
    }

    const setCraneSelected = (value: boolean) => {
        setIsCraneSelected(value);
    };

    const setDelevirySelected = (value: boolean) => {
        setIsDeliverySelected(value);
    };

    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    //function to check if any of the Radio Yes checked?
    const IsYesChecked = selectedOptions.filter((value: string) => value === 'Yes').length > 0;

    const handleOptionChange = (index: number, selectedOption: string) => {
        const updatedSelectedOptions = [...selectedOptions];
        updatedSelectedOptions[index] = selectedOption;
        setSelectedOptions(updatedSelectedOptions);
    };

    const hostedFormSubmit = (response: any) => {
        if (response.messages.message[0].code == 'I_WC_01') {
            setCreditInfo(true);
            let obj = {
                LastFour: response.encryptedCardData.bin.substr(response.encryptedCardData.bin.length - 4),
                dataDescriptor: response.opaqueData.dataDescriptor,
                dataValue: response.opaqueData.dataValue,
                CardNumber: response.encryptedCardData.cardNumber
            }
            setCreditData(obj);
            setCCError(false);
        }
    }

    const CADMutation = useCADMutationQuery();

    const submitHandler = (values: IValuesCAD, { setSubmitting }: FormikHelpers<IValuesCAD>) => {
        setLoading(true);
        const upload = [];
        const uploadedFiles: any[] = [];
        const data: any = {
            LocationId: eotId,
            ProductId: ProductdeliveryType.ProductId,
            CADTypeId: cadTypesData && cadTypesData[0].ProgramTypeId,
            CADSubTypeId: craneType.CADSubTypeId,
            TruckBedLength: values.truckBedLength,
            CranePostition: values.cranePosition,
            WorkingArea: values.workingArea,
            BoomTruck: values.isBoomTruck,
            Make: values.make,
            Model: values.model,
            SerialNumber: values.serialNumber,
            MaxRelatedCapacity: values.maxRelatedCapacity,
            BoomLength: values.boomLength,
            FrontOutrigger: values.frontOutrigger,
            BackOutrigger: values.backOutrigger,
            RopeSizeType: values.ropeSizeType,
            AllowableLine: values.allowableLine,
            DiameterTestWeight: values.diameterTestWeight,
            LoadChartUrl: values.loadChart,
            LinePullChartUrl: values.linePullChart,
            RangeDiagramUrl: values.rangeDiagram,
            DeliveryMethod: craneType.Name === 'CAD Expedited Fee' ? 'Standard' : 'Rush',
            Payment: {
                Paymenttype: 'Credit Card',
                AuthNetTransactionID: '',
                LastFour: creditData.LastFour,
                Amount: 100,
                PaymentData: {
                    BillToName: values.FirstName + " " + values.LastName,
                    BillToEmail: values.BillToEmail,
                    CreditCardType: '',
                    FirstName: values.FirstName,
                    LastName: values.LastName,
                    Address: values.Address,
                    City: values.City,
                    State: values.State,
                    Zip: values.Zip,
                    dataDescriptor: creditData.dataDescriptor,
                    dataValue: creditData.dataValue,
                    BillingPhone: values.BillingPhone,
                    BillingCountry: values.BillingCountry,
                }
            },
        };

        // let finalCheck = values.isBoomTruck === 'N_A';
        // if (finalCheck) {
        //     data.TruckBedLength = null;
        //     data.CranePostition = null;
        //     data.WorkingArea = null;
        // }
        if (data.LoadChartUrl instanceof File) {
            upload.push({
                name: 'loadChart',
                file: data.LoadChartUrl,
            });
        }

        if (data.LinePullChartUrl instanceof File) {
            upload.push({
                name: 'linePullChart',
                file: data.LinePullChartUrl,
            });
        }

        if (data.RangeDiagramUrl instanceof File) {
            upload.push({
                name: 'rangeDiagram',
                file: data.RangeDiagramUrl,
            });
        }

        if (upload.length) {
            upload.forEach(file => {
                const formData = new FormData();
                formData.append('file', file.file);
                uploadedFiles.push(
                    uploadFile.mutateAsync({
                        body: formData,
                        type: file.file.type.includes('image') ? 'image' : 'file',
                    })
                );
            });
        }

        Promise.all(uploadedFiles)
            .then(files => {
                if (3 === files.length) {
                    if (data.LoadChartUrl) {
                        data.LoadChartUrl = files[0].data.Data.FileName;
                    }

                    if (data.LinePullChartUrl) {
                        data.LinePullChartUrl = files[1].data.Data.FileName;
                    }

                    if (data.RangeDiagramUrl) {
                        data.RangeDiagramUrl = files[2].data.Data.FileName;
                    }
                }

                console.log(data)
                CADMutation.mutateAsync({ body: data, type: 'POST', }).then((res) => {
                    if (res?.data?.ApiCode === 200 && res.success === true) {
                        window.location.href = "/tsc-prac/";
                    }

                }).finally(() => {

                    setSubmitting(false);
                    setLoading(false);

                });
            })
            .catch(() => {
                setSubmitting(false);
                setLoading(false);
            });
    };

    return (
        <div id="main-content">
            <div className="cco_content_area cco_content_area_0">
                <article className="react-container">

                    <div className="cco_section cco_section_0">
                        <div className="cco_row cco_row_8">
                            <div className="cco_col">
                                <div className="cco_text cco_text_11">
                                    <div className="cco_text_inner">
                                        <strong><a href="/testing-personnel">Testing Personnel</a></strong>
                                        {' > '}
                                        <strong><a href="/tsc-prac">TSC-Practical</a></strong>
                                        {' > '}
                                        {'New Custom CAD'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Formik
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={CADSchema}
                            onSubmit={submitHandler}>
                            {({ values, submitForm, errors, isSubmitting, handleSubmit, handleReset, setFieldTouched, setFieldValue, touched }) => (
                                <form
                                    className="cco_form"
                                    onReset={handleReset}
                                    onSubmit={e => {
                                        console.log(values);
                                        if (isSubmitting) e.preventDefault();
                                        else handleSubmit(e);
                                    }}>
                                    {(
                                        <>
                                            {!isCraneSelected && (
                                                <><div className="cco_row" style={{ marginBottom: "20px" }}>
                                                    <div className="cco_col">
                                                        <div className="cco_card cco_card_2">
                                                            <div className="cco_card_body">
                                                                <div className="cco_card_main" style={{ padding: '0px' }}>
                                                                    <div className={'cco_text cco_text_9'}>
                                                                        <div className={'cco_text_inner'}>
                                                                            <h3>{'Custom CAD ' || ''}</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    <div className="cco_row ">
                                                        <div className="cco_col">
                                                            <div className="cco_text cco_text_0">
                                                                <div className="cco_text_inner">
                                                                    <strong>CAD Description </strong>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="cco_row">
                                                        <div className="cco_col">
                                                            <div className="cco_card cco_card_2">
                                                                <div className="cco_card_body">
                                                                </div>
                                                                <div className="cco_card_footer">
                                                                    <div className="cco_text">
                                                                        <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: descCAD || '' }}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cco_row">
                                                        <div className="cco_col">
                                                            <div className="cco_card cco_card_2">
                                                                <div className="cco_card_body">
                                                                    <div className="cco_text_0">
                                                                        Use the questions below to determine if a custom CAD is required.
                                                                    </div>
                                                                </div>
                                                                <div className="cco_card_footer">
                                                                    <div className="cco_text">
                                                                        <CADLanding handleOptionsChange={handleOptionChange} selectedOptions={selectedOptions} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="cco_form_row">
                                                        <div className="cco_form_col">
                                                            <div className="cco_form_wrap">
                                                                <div className="cco_text cco_text_8">
                                                                    <div className="cco_text_inner">To proceed with Custom CAD, select the Crane type:</div>
                                                                </div>
                                                                {cadTypesQuery.isLoading ? <Loader /> : (
                                                                    <ul className="cco_form_fieldset">

                                                                        {cadTypesData && cadSubTypesData.map((option, index) => (
                                                                            <div key={index} className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                                <label className="cco_form_checkbox_0">
                                                                                    <input
                                                                                        id={option.CADSubTypeId}
                                                                                        key={index}
                                                                                        type="radio"
                                                                                        name={`cranetype${index}`}
                                                                                        onChange={() => {
                                                                                            onCraneTypeChanged(option);
                                                                                            setFieldValue('craneType', option.Name);
                                                                                        }}
                                                                                        value={option.CADSubTypeId}
                                                                                        checked={IsYesChecked ? (craneType.CADSubTypeId === option.CADSubTypeId) : false}
                                                                                        className="cco_form_checkbox_input"
                                                                                        disabled={!IsYesChecked}
                                                                                    />
                                                                                    <span
                                                                                        className="cco_form_checkbox_checkmark"
                                                                                        style={{ border: IsYesChecked && (craneType.CADSubTypeId === option.CADSubTypeId) ? "2px solid #c67b1d" : '1px solid #c67b1d' }}
                                                                                    ></span>
                                                                                    {option.Name}
                                                                                </label>
                                                                            </div>
                                                                        ))}

                                                                    </ul>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {isCraneSelected && !isDeliverySelected && (
                                                <div className="cco_form_row">
                                                    <div className="cco_form_col">
                                                        <h2 className="cco_form_heading">{craneType.Name} Information</h2>

                                                        <div className="cco_form_wrap">
                                                            <ul className="cco_form_fieldset">


                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccomake"> Make <Required /></label>
                                                                    <Field type="text" id="ccomake" name="make" />
                                                                    <ErrorMessage name={'make'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccomodel">Model <Required /></label>
                                                                    <Field type="text" id="ccomodel" name="model" />
                                                                    <ErrorMessage name={'model'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoserialNumber">Serial Number <Required /></label>
                                                                    <Field type="text" id="ccoserialNumber" name="serialNumber" />
                                                                    <ErrorMessage name={'serialNumber'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccomaxRelatedCapacity">Max Related Capacity(Tons) <Required /></label>
                                                                    <Field type="number" id="ccomaxRelatedCapacity" name="maxRelatedCapacity" />
                                                                    <ErrorMessage name={'maxRelatedCapacity'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>
                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoboomLength">{values.maxRelatedCapacity && values.maxRelatedCapacity < 50 ? 'What boom length can you set that is between 70-90ft of boom' : 'What boom length can you set that is between 110-130ft of boom'} <Required /></label>
                                                                    <Field type="text" id="ccoboomLength" name="boomLength" />
                                                                    <ErrorMessage name={'boomLength'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                {values.craneType === 'LAT' ? (null) : (
                                                                    <><li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                        <label htmlFor="ccoisBoomTruck">Is this a boom truck? <Required /></label>
                                                                        <Field as="select" name="isBoomTruck" id="ccoisBoomTruck">
                                                                            <option value="default" disabled={true}>
                                                                                Select an option
                                                                            </option>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                            <option value="N_A">N/A</option>
                                                                        </Field>
                                                                        <ErrorMessage component={'div'} name={'isBoomTruck'} className="cco_form_message cco_form_message_error" />
                                                                    </li>


                                                                        {/* // <><li className="cco_form_fieldset_item cco_form_fieldset_item_small"></li><li className="cco_form_fieldset_item cco_form_fieldset_item_small"></li></> */}

                                                                        <><li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                            <label htmlFor="ccoworkingArea">Working area (degrees)<Required /></label>
                                                                            <Field as="select" name="workingArea" id="ccoworkingArea">
                                                                                <option value="default" disabled={true}>
                                                                                    Select an option
                                                                                </option>
                                                                                <option value="_180">180 degree</option>
                                                                                <option value="_270">270 degree</option>
                                                                                <option value="_360">360 degree</option>
                                                                            </Field>
                                                                            <ErrorMessage component={'div'} name={'workingArea'} className="cco_form_message cco_form_message_error" />
                                                                        </li>

                                                                            <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                                <label htmlFor="ccocranePosition">Crane Position<Required /></label>
                                                                                <Field as="select" name="cranePosition" id="ccocranePosition">
                                                                                    <option value="default" disabled={true}>
                                                                                        Select an option
                                                                                    </option>

                                                                                    <option value="BehindCab">Behind cab</option>
                                                                                    <option value="Center">Center mount</option>
                                                                                    <option value="Rear">Rear mount</option>
                                                                                    <option value="_5thWheel">Fifth wheel</option>
                                                                                </Field>

                                                                                <ErrorMessage component={'div'} name={'cranePosition'} className="cco_form_message cco_form_message_error" />
                                                                            </li>

                                                                            <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                                <label htmlFor="ccotruckBedLength">Truck Bed Length (ft)<Required /></label>
                                                                                <Field type="text" id="ccotruckBedLength" name="truckBedLength" />
                                                                                <ErrorMessage name={'truckBedLength'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                            </li>

                                                                        </>
                                                                    </>
                                                                )}



                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccofrontOutrigger">Front Outrigger/Stablizer Spread(ft)</label>
                                                                    <Field type="number" id="ccofrontOutrigger" name="frontOutrigger" />
                                                                    <ErrorMessage name={'frontOutrigger'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccobackOutrigger">Back Outrigger/Stablizer Spread(ft)</label>
                                                                    <Field type="number" id="ccobackOutrigger" name="backOutrigger" />
                                                                    <ErrorMessage name={'backOutrigger'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoropeSizeType">Rope Size & Type</label>
                                                                    <Field type="text" id="ccoropeSizeType" name="ropeSizeType" />
                                                                    <ErrorMessage name={'ropeSizeType'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                    <label htmlFor="ccoallowableLine">Allowable line pull as stated in the load chart (lb) <Required /></label>
                                                                    <Field type="text" id="allowableLine" name="allowableLine" />
                                                                    <ErrorMessage name={'allowableLine'} component={'div'} className="cco_form_message cco_form_message_error" />
                                                                </li>

                                                                <li className={'cco_form_fieldset_item cco_form_fieldset_item_medium'}>
                                                                    <label htmlFor="ccodiameterTestWeight">Diameter of test weight (ft) <Required /></label>
                                                                    <Field type="text" id="diameterTestWeight" name="diameterTestWeight" />
                                                                    <ErrorMessage name="diameterTestWeight" component="div" className="cco_form_message cco_form_message_error" />
                                                                </li>







                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                    <label htmlFor="ccoloadChart">
                                                                        Upload Load Chart Documentation <Required />
                                                                    </label>

                                                                    <div className="cco_form_field cco_form_field_0">
                                                                        <div className="cco_form_field_body">
                                                                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoloadChart">
                                                                                {(values.loadChart instanceof File && values.loadChart.type.includes('image')) ||
                                                                                    ('string' === typeof values.loadChart &&
                                                                                        values.loadChart &&
                                                                                        !values.loadChart.includes('.pdf')) ? (
                                                                                    <>
                                                                                        <img
                                                                                            src={
                                                                                                values.loadChart instanceof File
                                                                                                    ? URL.createObjectURL(values.loadChart)
                                                                                                    : values.loadChart
                                                                                            }
                                                                                            onLoad={e => {
                                                                                                const src = e.currentTarget.src;

                                                                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                                                            }}
                                                                                            alt="user profile picture"
                                                                                        />
                                                                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click to Upload</span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                                                                        <span className="cco_form_field_label_caption">Click to Upload</span>
                                                                                    </>
                                                                                )}
                                                                            </label>

                                                                            <Field
                                                                                type={'file'}
                                                                                id={'ccoloadChart'}
                                                                                accept={'.pdf'}
                                                                                value={undefined}
                                                                                name={'loadChart'}
                                                                                className={'cco_form_field_input'}
                                                                                onChange={(event: any) => {
                                                                                    if (!event.currentTarget.files[0]) {
                                                                                        return;
                                                                                    }

                                                                                    setFieldTouched('loadChart', true);
                                                                                    setFieldValue('loadChart', event.currentTarget.files[0], true);
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="cco_form_field_aside">
                                                                            {values.loadChart && !errors.loadChart && touched.loadChart && (
                                                                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                                                                    {values.loadChart instanceof File ? values.loadChart.name : null /* values.termsAndConditions */}
                                                                                </div>
                                                                            )}

                                                                            <ErrorMessage
                                                                                name={'loadChart'}
                                                                                component={'div'}
                                                                                className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="cco_form_btn cco_form_btn_2">
                                                                        <a href="javascript:void(0)">
                                                                            Download Load Chart
                                                                        </a>
                                                                    </div>
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                    <label htmlFor="ccoTCTSR">
                                                                        Upload Line Pull Chart  <Required />
                                                                    </label>

                                                                    <div className="cco_form_field cco_form_field_0">
                                                                        <div className="cco_form_field_body">
                                                                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoTCTSR">
                                                                                {(values.linePullChart instanceof File && values.linePullChart.type.includes('image')) ||
                                                                                    ('string' === typeof values.linePullChart &&
                                                                                        values.linePullChart &&
                                                                                        !values.linePullChart.includes('.pdf')) ? (
                                                                                    <>
                                                                                        <img
                                                                                            src={
                                                                                                values.linePullChart instanceof File
                                                                                                    ? URL.createObjectURL(values.linePullChart)
                                                                                                    : values.linePullChart
                                                                                            }
                                                                                            onLoad={e => {
                                                                                                const src = e.currentTarget.src;

                                                                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                                                            }}
                                                                                            alt="user profile picture"
                                                                                        />
                                                                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click to Upload</span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                                                                        <span className="cco_form_field_label_caption">Click to Upload</span>
                                                                                    </>
                                                                                )}
                                                                            </label>

                                                                            <Field
                                                                                type={'file'}
                                                                                id={'ccoTCTSR'}
                                                                                accept={'.pdf'}
                                                                                value={undefined}
                                                                                name={'linePullChart'}
                                                                                className={'cco_form_field_input'}
                                                                                onChange={(event: any) => {
                                                                                    if (!event.currentTarget.files[0]) {
                                                                                        return;
                                                                                    }

                                                                                    setFieldTouched('linePullChart', true);
                                                                                    setFieldValue('linePullChart', event.currentTarget.files[0], true);
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="cco_form_field_aside">
                                                                            {values.linePullChart && !errors.linePullChart && touched.linePullChart && (
                                                                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                                                                    {values.linePullChart instanceof File ? values.linePullChart.name : null /* values.termsAndConditions */}
                                                                                </div>
                                                                            )}

                                                                            <ErrorMessage
                                                                                name={'linePullChart'}
                                                                                component={'div'}
                                                                                className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="cco_form_btn cco_form_btn_2">
                                                                        <a href="javascript:void(0)">
                                                                            Download Line Pull
                                                                        </a>
                                                                    </div>
                                                                </li>

                                                                <li className="cco_form_fieldset_item cco_form_fieldset_item_small">
                                                                    <label htmlFor="ccoTCTSR">
                                                                        Upload Range Diagram <Required />
                                                                    </label>

                                                                    <div className="cco_form_field cco_form_field_0">
                                                                        <div className="cco_form_field_body">
                                                                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccorangeDiagram">
                                                                                {(values.rangeDiagram instanceof File && values.rangeDiagram.type.includes('image')) ||
                                                                                    ('string' === typeof values.rangeDiagram &&
                                                                                        values.rangeDiagram &&
                                                                                        !values.rangeDiagram.includes('.pdf')) ? (
                                                                                    <>
                                                                                        <img
                                                                                            src={
                                                                                                values.rangeDiagram instanceof File
                                                                                                    ? URL.createObjectURL(values.rangeDiagram)
                                                                                                    : values.rangeDiagram
                                                                                            }
                                                                                            onLoad={e => {
                                                                                                const src = e.currentTarget.src;

                                                                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                                                            }}
                                                                                            alt="user profile picture"
                                                                                        />
                                                                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click to Upload</span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                                                                        <span className="cco_form_field_label_caption">Click to Upload</span>
                                                                                    </>
                                                                                )}
                                                                            </label>

                                                                            <Field
                                                                                type={'file'}
                                                                                id={'ccorangeDiagram'}
                                                                                accept={'.pdf'}
                                                                                value={undefined}
                                                                                name={'rangeDiagram'}
                                                                                className={'cco_form_field_input'}
                                                                                onChange={(event: any) => {
                                                                                    if (!event.currentTarget.files[0]) {
                                                                                        return;
                                                                                    }

                                                                                    setFieldTouched('rangeDiagram', true);
                                                                                    setFieldValue('rangeDiagram', event.currentTarget.files[0], true);
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="cco_form_field_aside">
                                                                            {values.rangeDiagram && !errors.rangeDiagram && touched.rangeDiagram && (
                                                                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                                                                    {values.rangeDiagram instanceof File ? values.rangeDiagram.name : null /* values.termsAndConditions */}
                                                                                </div>
                                                                            )}

                                                                            <ErrorMessage
                                                                                name={'rangeDiagram'}
                                                                                component={'div'}
                                                                                className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="cco_form_btn cco_form_btn_2">
                                                                        <a href="javascript:void(0)">
                                                                            Download Range Diagram
                                                                        </a>
                                                                    </div>
                                                                </li>

                                                                <div className="cco_text cco_text_8">
                                                                    <div className="cco_text_inner">Select which type of delivery method you would like to purchase:</div>
                                                                </div>
                                                                {productRushQuery.isLoading || productStandardQuery.isLoading ? <Loader /> : (
                                                                    <ul className="cco_form_fieldset_item">
                                                                        {deliveryTypes.map((option, index) => (
                                                                            <div key={index} className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                                                                                <label className="cco_form_checkbox_0 ">
                                                                                    <input
                                                                                        id={option.ProductId}
                                                                                        type="radio"
                                                                                        name={`deliveryMethod`}
                                                                                        onChange={() => onDeliveryTypeChanged(option)}
                                                                                        value={option.ProductId}
                                                                                        checked={ProductdeliveryType.ProductId === option.ProductId}
                                                                                        className="cco_form_checkbox_input"
                                                                                    />
                                                                                    <span
                                                                                        className="cco_form_checkbox_checkmark"
                                                                                        style={{ border: ProductdeliveryType.ProductId === option.ProductId ? "2px solid #c67b1d" : '1px solid #c67b1d' }}
                                                                                    ></span>
                                                                                    {option.ProductName === 'CAD Expedited Fee' ? `$${option.ProductPrice + ' - Standard Custom CAD delivery is 5-7 Business Days.'}` : `$${option.ProductPrice + ' - Rush Delivery 2-3 Business Days.'}`}
                                                                                </label>
                                                                            </div>
                                                                        ))}

                                                                    </ul>
                                                                )}


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}


                                            <div className="cco_form_row">
                                                <div className="cco_form_col">


                                                    {isCraneSelected && isDeliverySelected && ProductdeliveryType.ProductId && (
                                                        <BillingInformation authData={authData} CardNumber={creditData.CardNumber} handler={hostedFormSubmit} isCreditInfo={creditInfo} showCreditCardErrorMessage={ccError} ProductData={ProductdeliveryType} />
                                                    )}

                                                    {!isSubmitting && (
                                                        <>
                                                            {CADMutation.data?.success && (
                                                                <div
                                                                    className="cco_form_message cco_form_message_success"
                                                                    style={{ fontSize: 16, marginBottom: 20 }}>
                                                                    <strong>
                                                                        Successfully added Custom CAD.
                                                                    </strong>
                                                                </div>
                                                            )}

                                                            {CADMutation.error && (
                                                                <div
                                                                    className="cco_form_message cco_form_message_error"
                                                                    style={{
                                                                        marginBottom: 20
                                                                    }}>
                                                                    {(CADMutation.error as Error).message}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}




                                                    <div className="cco_form_row">
                                                        <div className="cco_form_col">
                                                            <ul className="cco_menu cco_menu_4" >

                                                                <li className={`cco_menu_item cco_menu_item_btn ${craneType.CADSubTypeId === '' ? 'cco_menu_item_btn_disabled' : ''}`}>
                                                                    <a href={craneType.CADSubTypeId === '' ? `javascript:void(0)` : `javascript:void(0)`} onClick={async (e) => {
                                                                        e.preventDefault();
                                                                        const stepIndex = isDeliverySelected ? 2 : isCraneSelected ? 1 : 0 as keyof typeof screenedValuesCAD;
                                                                        const error = screenedValuesCAD[stepIndex].some(field => errors.hasOwnProperty(field));

                                                                        if (craneType.CADSubTypeId) {
                                                                            if (!isCraneSelected) {
                                                                                setCraneSelected(true);
                                                                                window.scrollTo(0, 0);
                                                                            } else if (!isDeliverySelected && ProductdeliveryType.ProductId && !error) {
                                                                                setDelevirySelected(true);
                                                                                setbuttonText('Pay $' + ProductdeliveryType.ProductPrice);
                                                                                window.scrollTo(0, 0);
                                                                            } else if (stepIndex === 2 && creditInfo === false && initialValues.dataDescriptor === '' && initialValues.dataValue === '') {
                                                                                setCCError(true);
                                                                                window.scrollTo(0, 0);
                                                                                e.preventDefault();
                                                                            } else if (isDeliverySelected && isCraneSelected && !error && creditInfo === true) {
                                                                                await submitForm();
                                                                            }
                                                                        } else {
                                                                            e.preventDefault();
                                                                        }
                                                                        screenedValuesCAD[stepIndex].forEach(field => setFieldTouched(field, true, true));
                                                                        window.scrollTo(0, 0);

                                                                    }}>{buttonText}</a>
                                                                </li>



                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </form>
                            )}
                        </Formik>

                        <BlockUI title={'Please wait while payment is processed. Do not refresh page or click back button.'} blocking={showLoading || CADMutation.isSuccess}></BlockUI>
                    </div>
                </article>
            </div>
        </div>

    );
};
