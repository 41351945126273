import classNames from "classnames";
import { useState } from "react";
import { AvailableCredential } from "../../types/allcredential";
import { Link } from "react-router-dom";

interface IAvailableCredential {
    credential: AvailableCredential;
}

export const CredentialsAccordion = ({ credential }: IAvailableCredential) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <li className={classNames('cco_accordion_item ac_card_hover', { active: isOpen })} style={{ listStyle: 'none' }}>
            <div className="cco_accordion_item_header_custom">
            <Link to={'/credential'} state={{id:credential.CredentialId}} style={{ color: 'rgb(102, 102, 102)' }} >

                {/* <a href={"/credential/" + credential.CredentialId} style={{ color: 'rgb(102, 102, 102)' }}> */}
                    <div className="cco_text cco_text_3">
                        <div className="cco_text_inner">
                            <h3>{credential.myCCOLabel || 'Exam Name'}</h3>
                        </div>
                    </div>
                </Link>
                <button className="cco_accordion_item_toggle_custom" onClick={() => setIsOpen(prev => !prev)}>
                    <span className="cco_accordion_item_toggle_custom_text_custom">View</span>
                </button>
              </div>
            {/* <a href={"/credential/" + credential.CredentialId} style={{ color: 'rgb(102, 102, 102)' }}> */}
            <Link to={'/credential'} state={{id:credential.CredentialId}} style={{ color: 'rgb(102, 102, 102)' }} >

                <div className="cco_accordion_item_body">
                    <div className="cco_accordion_item_body_inner">
                        <table className="cco_table">
                            <tbody className="cco_table_body">
                                <tr className="cco_table_row">
                                    {credential.ShortDescription && (
                                        <td className="cco_table_cell" style={{ width: '100%' }}>
                                            <div className="cco_blurb cco_blurb_0">
                                                <div className="cco_text_2">
                                                    <div className="cco_text_inner" dangerouslySetInnerHTML={{ __html: credential?.ShortDescription || '' }}></div>
                                                </div>
                                            </div>
                                        </td>
                                    )}

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </Link>
            {/* </a> */}
        </li>
    );
};