import { Root } from '../types/dashboard';
import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { RootObject } from '../types/profilephoto';
export const useUser = () => {
    return useQuery<Root, Error>(['dashboard'], async () => {
        const jsonResponse = await request({
            endpoint: 'ContactInfo',
        });
        const params = new URL(window.location as any).searchParams;

        if (!jsonResponse.data.IsProfileCompleted && null === params.get('incomplete')) {
            window.location.href = 'account/?incomplete';
            throw new Error('Incomplete profile');
        }

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};

export const useUserProfilePicture = () => {
    return useQuery<RootObject, Error>(['profilePicture'], async () => {
        const jsonResponse = await request({
            endpoint: 'GetProfilePitureURL',
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};
