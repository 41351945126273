import type { RootObject } from '../types/timezone';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const useTimezone = (enabled = true) => {
    return useQuery<RootObject, Error>(
        ['timezones'],
        async () => {
            const jsonResponse = await request({
                endpoint: 'locations/get-timezones',
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled,
        }
    );
};
