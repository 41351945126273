import { Field, ErrorMessage } from 'formik';

export const Attestation = () => {
    return (
        <div className="cco_form_row">
            <div className="cco_form_col">
                <h2 className="cco_form_heading">6. Attestation</h2>

                <div className="cco_form_wrap">
                    <div className="cco_text cco_text_8">
                        <div className="cco_text_inner">Do you have the following requirements?</div>
                    </div>
                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoParking" type="checkbox" name="attestation" value="parking" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                Adequate parking for the number of anticipated vehicles
                            </label>
                        </li>
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoRestroom" type="checkbox" name="attestation" value="restroom" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                Restrooms available on-site
                            </label>
                        </li>
                        {/* Newly Added Field in Practical Location */}
                        <li className="cco_form_fieldset_item cco_form_fieldset_item_medium">
                            <label className="cco_form_checkbox">
                                <Field id="ccoRestroom" type="checkbox" name="attestation" value="pretest" className="cco_form_checkbox_input" />
                                <span className="cco_form_checkbox_checkmark"></span>
                                A pre-test briefing area for candidates waiting to test (unable to observe candidates who are live testing)
                            </label>
                        </li>
                    </ul>

                    <ErrorMessage component={'div'} name={'attestation'} className="cco_form_message cco_form_message_error" />
                </div>
            </div>
        </div>
    );
};
