import type { IValues } from './valuesTypes';

import { ErrorMessage, Field, useFormikContext } from 'formik';

export const LocationDoorSign = () => {
    const { values, errors, setFieldTouched, setFieldValue, touched } = useFormikContext<IValues>();

    return (
        <div className="cco_form_row">
            <div className="cco_form_col cco_form_col_1">
                <h2 className="cco_form_heading">2. Photo of Testing Facility including Signage</h2>

                <div className="cco_form_wrap">
                    <div className="cco_form_fieldset cco_form_fieldset_2">
                        <div className="cco_form_fieldset_item">
                            <label htmlFor="ccoDoorSign">
                                Upload Testing Facility Photo <small>5MB max</small>
                            </label>

                            <div className="cco_form_field cco_form_field_0">
                                <div className="cco_form_field_body">
                                    <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoDoorSign">
                                        {(values.doorSign instanceof File && values.doorSign.type.includes('image')) ||
                                        ('string' === typeof values.doorSign && values.doorSign && !values.doorSign.includes('.pdf')) ? (
                                            <>
                                                <img
                                                    src={values.doorSign instanceof File ? URL.createObjectURL(values.doorSign) : values.doorSign}
                                                    onLoad={e => {
                                                        const src = e.currentTarget.src;

                                                        if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                                    }}
                                                    alt=""
                                                />
                                                <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click Here to Upload</span>
                                            </>
                                        ) : (
                                            <>
                                                <span className="cco_form_field_label_icon cco_form_field_label_icon_camera"></span>
                                                <span className="cco_form_field_label_caption">Click Here to Upload</span>
                                            </>
                                        )}
                                    </label>

                                    <Field
                                        type={'file'}
                                        name={'doorSign'}
                                        value={undefined}
                                        id={'ccoDoorSign'}
                                        className={'cco_form_field_input'}
                                        accept={'.pdf, image/png, image/jpeg'}
                                        onChange={(event: any) => {
                                            if (!event.currentTarget.files[0]) {
                                                return;
                                            }

                                            setFieldTouched('doorSign', true);
                                            setFieldValue('doorSign', event.currentTarget.files[0], true);
                                        }}
                                    />
                                </div>

                                <div className="cco_form_field_aside">
                                    {values.doorSign && !errors.doorSign && touched.doorSign && (
                                        <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                            {values.doorSign instanceof File ? values.doorSign.name : null /* values.doorSign */}
                                        </div>
                                    )}

                                    <ErrorMessage
                                        name={'doorSign'}
                                        component={'div'}
                                        className="cco_form_message cco_form_message_has_icon cco_form_message_error"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="cco_form_fieldset_item">
                            <div className="cco_form_label">Sample Testing Facility Photo</div>

                            <img className="cco_form_img" srcSet={'https://portal-test.nccco.org/wp-content/themes/Divi-Child/assets/images/TFP_Sample.jpg'} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="cco_form_col cco_form_col_2">
                <h2 className="cco_form_heading">3. Testing Room Layout</h2>

                <div className="cco_form_wrap">
                    <label htmlFor="ccoSitePlan">
                        Upload Testing Room Layout <small>10MB max</small>
                    </label>

                    <div className="cco_form_field cco_form_field_0">
                        <div className="cco_form_field_body">
                            <label className="cco_form_field_label ccs_loader_relative_container" htmlFor="ccoSitePlan">
                                {(values.sitePlan instanceof File && values.sitePlan.type.includes('image')) ||
                                ('string' === typeof values.sitePlan && values.sitePlan && !values.sitePlan.includes('.pdf')) ? (
                                    <>
                                        <img
                                            src={values.sitePlan instanceof File ? URL.createObjectURL(values.sitePlan) : values.sitePlan}
                                            onLoad={e => {
                                                const src = e.currentTarget.src;

                                                if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                            }}
                                            alt=""
                                        />
                                        <span className="cco_form_field_label_caption cco_form_field_label_caption_hover">Click Here to Upload</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="cco_form_field_label_icon cco_form_field_label_icon_file"></span>
                                        <span className="cco_form_field_label_caption">Click Here to Upload</span>
                                    </>
                                )}
                            </label>

                            <Field
                                type={'file'}
                                value={undefined}
                                name={'sitePlan'}
                                id={'ccoSitePlan'}
                                className={'cco_form_field_input'}
                                accept={'.pdf, image/png, image/jpeg'}
                                onChange={(event: any) => {
                                    if (!event.currentTarget.files[0]) {
                                        return;
                                    }

                                    setFieldTouched('sitePlan', true);
                                    setFieldValue('sitePlan', event.currentTarget.files[0], true);
                                }}
                            />
                        </div>

                        <div className="cco_form_field_aside">
                            {values.sitePlan && !errors.sitePlan && touched.sitePlan && (
                                <div className="cco_form_message cco_form_message_has_icon cco_form_message_success">
                                    {values.sitePlan instanceof File ? values.sitePlan.name : null /* values.sitePlan */}
                                </div>
                            )}

                            <ErrorMessage name={'sitePlan'} component={'div'} className="cco_form_message cco_form_message_has_icon cco_form_message_error" />
                        </div>
                    </div>

                    <div className="cco_form_btn cco_form_btn_2">
                        <a href={'https://www.nccco.org/EOTlayout'} target="_blank">
                            Download Testing Room Layout Form
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
