import { RootObject } from '../types/eot';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';

export const useEot = (eotId?: string | null) => {
    return useQuery<RootObject, Error>(
        ['eot', eotId],
        async () => {
            const jsonResponse = await request({
                endpoint: 'locations/get',
                data: {
                    LocationId: eotId,
                },
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
        {
            enabled: !!eotId,
        }
    );
};
