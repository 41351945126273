import { useQuery } from "@tanstack/react-query";
import { request } from "../services/request";
import { RootObject1 } from "../types/prerequisite";

export const usePrerequisiteTree = (credentailId:string) => {
    return useQuery<RootObject1, Error>(
        ['credential-prerequisite-tree'],
        async () => {
            const jsonResponse = await request({
                endpoint: 'credentials/credential-prerequisite-tree',
                data: {
                    credentialId:credentailId
                },
                
            });

            if (!jsonResponse.success) {
                throw new Error(jsonResponse.data.Message || 'Something went wrong');
            }

            return jsonResponse;
        },
      
    );
};
