import { request } from '../services/request';
import { useMutation } from '@tanstack/react-query';

export const useUpload = () =>
    useMutation<any, Error, any>(async (data: { body: FormData; type: 'image' | 'file' }) => {
        const jsonResponse = await request({
            type: 'data',
            method: 'POST',
            data: data.body,
            endpoint: `files/upload-temp-${data.type}`,
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
