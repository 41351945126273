import * as yup from 'yup';

import 'yup-phone';

const getRequiredMessage = (field: string) => `The ${field} field is required`;

export const accountSchema = yup.object().shape({
    zip: yup.string().trim().required(getRequiredMessage('zip')),
    city: yup.string().trim().required(getRequiredMessage('city')),
    street1: yup.string().trim().required(getRequiredMessage('street1')),
    phone: yup.string().required(getRequiredMessage('phone')),
    state: yup.string().test('select-state', getRequiredMessage('state'), value => value !== 'default'),
    country: yup.string().test('select-country', getRequiredMessage('country'), value => value !== 'default'),
});
