import type { Root } from '../../types/dashboard';

import { Loader } from '../../components/Loader';
import { useCredentials } from '../../hooks/useCredentials';
import { useUser, useUserProfilePicture } from '../../hooks/useUser';
import { NullBadgeUrl, getUserBasicData } from "../../tools/utils";
import { Link } from 'react-router-dom';
import { Credentials } from '../../types/allcredential';
import React from 'react';

export const TestingPersonnel = () => {

  const data = getUserBasicData();
  const credentials = useCredentials();
  const userCredentials = credentials?.data?.data?.Data;
  const credentialGroupedData = userCredentials && userCredentials?.MyCredentials
    .sort((a, b) => a.EarnedCredential.GroupOrder - b.EarnedCredential.GroupOrder)
    .reduce((groups: any, item) => {
      const { GroupName } = item.EarnedCredential;
      if (!groups[GroupName]) {
        groups[GroupName] = [];
      }
      groups[GroupName].push(item.EarnedCredential);
      return groups;
    }, {});

    const credentialGroup: Array<{ [x: string]: unknown; }> = credentialGroupedData && Object.entries(credentialGroupedData).map(([GroupName, groupItems]) => ({
      [GroupName]: groupItems,
    }));

  return (
    <div id="main-content">
    <div className="cco_content_area cco_content_area_0">
    <article className="react-container" >

  <div className="cco_section cco_section_0">
      <div className="cco_row cco_row_4">
        <div className="cco_col">
          <div className="cco_text cco_text_0">
            <div className="cco_text_inner">
              <strong>Testing Personnel</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="cco_row cco_row_0">
        <>
          {/* <div className="cco_col cco_col_5"> */}
          <div className="cco_col cco_col_12">
            <div className="cco_text cco_text_1">
              <div className="cco_text_inner">
                <h1>
                  {data.FullName.toUpperCase()}
                  <span className="divider" />
                  <small>
                    CCO ID <strong>{data.CandidateId}</strong>
                  </small>
                </h1>
              </div>
            </div>
          </div>
        </>
      </div>

      {credentialGroup && credentialGroup.map((group: any, index: number) => (
        <React.Fragment key={group + index}>
          <div className="cco_row cco_row_7">
            <div className="cco_col cco_col_6">
              <div className="cco_text cco_text_10">
                <div className="cco_text_inner">
                  <h2>My {Object.keys(group)[0]}</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="cco_row cco_row_3">
            <div className="cco_col">
              {credentials.isLoading ? (
                <Loader />
              ) : credentials.error ? (
                <p>{credentials.error.message}</p>
              ) : group && group[Object.keys(group)[0]].length > 0 ? (
                <div className="cco_group cco_group_myCredential" >
                  {group[Object.keys(group)[0]].sort((a: { CredentialOrder: number; }, b: { CredentialOrder: number; }) => a.CredentialOrder - b.CredentialOrder).map(
                    (credential: Credentials) => (
                      <div className="cco_group_item tpCards" key={credential.Id}>
                        <Link 
                        to={'/my-credential'}
                        state={{id:credential.ContactCredentialId}}
                        >
                        {/* <a href={"/my-credential/" + credential.ContactCredentialId} > */}
                          <div className="cco_card cco_card_myCredential" >
                            <div className="cco_card_body">
                              <div className="cco_card_aside">
                                <div className="cco_img">
                                  <span className="cco_img_wrap">
                                    <img
                                      style={{
                                        border:
                                          credential.StatusReason === "Approved" &&
                                            credential.BadgeUrl != null
                                            ? ""
                                            : "1px solid rgb(0 0 0 / 5%)",
                                        borderRadius:
                                          credential.StatusReason === "Approved" &&
                                            credential.BadgeUrl != null
                                            ? ""
                                            : "23px",
                                      }}
                                      alt={credential?.myCCOLabel}
                                      src={
                                        credential.StatusReason === "Approved" &&
                                          credential.BadgeUrl != null
                                          ? credential.BadgeUrl
                                          : NullBadgeUrl
                                      }
                                    />
                                  </span>
                                </div>
                              </div>
                              <div
                                className="cco_card_main"
                                style={{ width: "100%", padding: "10px" }}
                              >
                                <div className="cco_text cco_text_myCredential">
                                  <div className="cco_text_inner">
                                    <h3>{credential?.myCCOLabel}</h3>
                                    <div>
                                      Status: {credential.StatusReason}
                                    </div>
                                    <div>
                                      Training Due:{" "}
                                      {credential?.ExpiryDate?.split("T")[0] === "0001-01-01"
                                        ? "Not Available"
                                        : credential?.ExpiryDate?.split("T")[0]}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </Link>
                        {/* </a> */}
                      </div>
                    )
                  )}
                </div>
              ) : (
                <p>No Credentials available</p>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}




      {/*Available Credential  */}
      <div className="cco_row cco_row_2">
        <div className="cco_col">
          <div className="cco_text cco_text_2">
            <div className="cco_text_inner">
              {/* <h2>Available Credentials</h2> */}

            </div>
          </div>
        </div>
      </div>


      {credentials.isLoading ? (
        <div className='cco_row cco_row_3'>
          <Loader />
        </div>
      ) : credentials.error ? (
        <p>{credentials.error.message}</p>
      ) : (
        <div className="cco_row cco_row_3" style={{ backgroundColor: 'white' }}>
          <div className="cco_col">
            <div className="cco_group cco_group_2" >
              <div className="cco_group_item" >
                <ul className="cco_menu cco_menu_6" style={{ textAlign: 'center' }}>
                  <li className="cco_menu_item cco_menu_item_primary" style={{ marginBottom: "0px" }}  >
                    <a style={{ height: "auto" }} href="/credential-journey">Start Credential Journey</a>
                    <div style={{ textAlign: "center" }}>
                      <span>
                        <a className='ccolinks' href={'/available-credentials'} >View all Available Credentials</a>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}


    </div>
    </article>
    </div>
    </div>
  );
};