import { useState } from 'react';

import type { Datum } from '../../types/tsc';

import moment from 'moment';
import classNames from 'classnames';
import { formatAddress } from '../../tools/utils';
import { Link } from 'react-router-dom';

interface TSCProps {
    item: Datum;
    type?: 'PendingReview' | 'Approved' | 'Expired' | 'PendingPayment';
}

export const TSC = ({ item, type }: TSCProps) => {
    const [active, setActive] = useState(false);

    return (
        <li className={classNames('cco_accordion_item', { active })}>
            <div className="cco_accordion_item_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>
                            {item.CompanyName || '[Company Name]'}
                            <span> - </span>
                            {formatAddress({
                                Zip: item.Zip,
                                City: item.City,
                                Type: 'Formatted',
                                Street2: item.Address2,
                                Street1: item.Address1,
                                State: item.StateProvince.Name,
                            })}
                        </h3>

                        {(('Approved' === item.Status && item.TestSiteNumber) || 'Expired' === type) && (
                            <div>{item.TestSiteNumber}</div>
                        )}
                    </div>
                </div>
                <button className="cco_accordion_item_toggle" onClick={() => setActive(prevState => !prevState)}>
                    <span className="cco_accordion_item_toggle_text">View</span>
                </button>
            </div>
            <div className="cco_accordion_item_body">
                <div className="cco_accordion_item_body_inner">
                    <table className="cco_table cco_table_1">
                        <tbody className="cco_table_body">
                            <tr className="cco_table_row">
                                <td className="cco_table_cell cco_table_cell_medium">
                                    <div className="cco_blurb cco_blurb_0">
                                        <div className="cco_blurb_label">Company Name</div>
                                        <div className="cco_blurb_value">
                                            <strong>{item.CompanyName || '[Company Name]'}</strong>
                                        </div>
                                    </div>
                                    <div className="cco_blurb cco_blurb_0">
                                        <div className="cco_blurb_label">Address</div>
                                        <div className="cco_blurb_value">
                                            <strong>
                                                {formatAddress({
                                                    Zip: item.Zip,
                                                    City: item.City,
                                                    Type: 'Formatted',
                                                    Street1: item.Address1,
                                                    Street2: item.Address2,
                                                    State: item.StateProvince.Name,
                                                })}
                                            </strong>
                                        </div>
                                    </div>
                                </td>
                                <td className="cco_table_cell cco_table_cell_small">
                                    <div className="cco_blurb cco_blurb_align_center cco_blurb_0"></div>
                                    <div className="cco_blurb cco_blurb_align_center cco_blurb_0"></div>
                                </td>
                                <td className="cco_table_cell cco_table_cell_small">
                                    <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                        <div className="cco_blurb_label">Status</div>
                                        <div className="cco_blurb_value">
                                            <strong>{item.Status}</strong>
                                        </div>
                                    </div>
                                    {'PendingReview' !== type && 'PendingPayment' !== type && (
                                        <div className="cco_blurb cco_blurb_align_right cco_blurb_0">
                                            <div className="cco_blurb_label">Expire Date</div>
                                            <div className="cco_blurb_value">
                                                <strong>{moment(item.ExpireDate).local().format('MM/DD/YYYY')}</strong>
                                            </div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    {'PendingReview' !== type && (
                        <ul className="cco_menu cco_menu_3">
                            <li className="cco_menu_item">
                                {/* <a href={`/tsc-eot/location-edit/${item.LocationId}`}>Edit</a> */}
                                <Link to={`/tsc-eot/location-edit`} state={{ eotId: item.LocationId, single: true} } >Edit</Link>
                            </li>

                            {'PendingPayment' !== type  && item.IsRenewAvailable && 1>2 && (
                                <li className="cco_menu_item">
                                    <a href="/tsc-eot/location-renew">Renew Now</a>
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </li>
    );
};
