import React from "react";

interface defaultProps {
  blocking?: boolean,
  title?: string | 'Loading',

};

export const BlockUI = (props: defaultProps) => {
  if (!props.blocking) {
    document.body.style.overflowY = "auto";
    return null;
  } else {

    window.scrollTo(0, 0)
    document.body.style.overflowY = "hidden";
    return (
      <div className="block-ui-container">
        <div className="block-ui-overlay" />
        <div className="block-ui-message-container">
          <div className="block-ui-message">
            <div className="loading-indicator">
              <svg id="indicator" viewBox="0 0 100 100">
                <circle id="circle" cx="50" cy="50" r="45" />
              </svg>
            </div>
            <h4>{props.title}</h4>
          </div>
        </div>
      </div>
    );
  }
};


