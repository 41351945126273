
export const NotFound404 = () => {
    return (

        <div id="main-content">
            <div class="cco_content_area cco_content_area_0">
                <div class="cco_section cco_section_0">
                    <div class="cco_row">
                        <div class="cco_col">
                            <div id="left-area">
                                <article id="post-0" className="et_pb_post not_found">
                                    <div class="entry">
                                        <h1 class="not-found-title">No Results Found</h1>
                                        <p>The page you requested could not be found. Try refining your search, or use the navigation above to locate the page.</p>
                                    </div>
                                </article>
                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </div>
    )
};