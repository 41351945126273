import type { RootObject } from '../types/tsc';

import { useQuery } from '@tanstack/react-query';
import { request } from '../services/request';
import { getContactId } from '../tools/utils';

export const useEOTTestSites = () => {
    return useQuery<RootObject, Error>(['tsc_eot'], async () => {
        const jsonResponse = await request({
            endpoint: 'locations/get-eot-ts',
            data: {
                tscId: await getContactId(),
            },
        });

        if (!jsonResponse.success) {
            throw new Error(jsonResponse.data.Message || 'Something went wrong');
        }

        return jsonResponse;
    });
};
