import type { IValues } from '../valuesTypes';

import classNames from 'classnames';

import { useState, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { screenedValues } from '../../../tools/constants';

export const InternetSpeed = ({
    handler,
    editable = true,
}: {
    handler?: (step: number) => void;
    editable?: boolean;
}) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValues>();

    const [isExpanded, setIsExpanded] = useState(false);

    const isFormCompleted = useMemo(
        () => screenedValues[2].every(field => !errors[field] && touched[field]),
        [errors, touched]
    );

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>5. Test Site Capacity</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted,
                            })}
                        >
                            {isFormCompleted
                                ? 'Completed'
                                : submitCount < 1
                                ? 'In Progress'
                                : 'Please fill out all fields'}
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">View</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    <div className="cco_blurb cco_blurb_1">
                        <div className="cco_blurb_label">Testing stations</div>
                        <div className="cco_blurb_value">
                            <strong>{values.numberOfCandidateTestingStations || 'Not completed'}</strong>
                        </div>
                    </div>

                    <div
                        className={classNames('cco_blurb cco_blurb_3', {
                            cco_blurb_success: 'yes' === values.internetSpeed,
                            cco_blurb_error: 'no' === values.internetSpeed,
                        })}
                    >
                        <div>
                            <p>Do you have the minimum download/upload speed required?</p>

                            {'default' === values.internetSpeed && (
                                <p className="cco_blurb cco_blurb_3 cco_blurb_error cco_form_message_error_footer">
                                    Not answered
                                </p>
                            )}
                        </div>
                    </div>

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(2);
                                    e.preventDefault();
                                }}
                            >
                                Edit
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
