import { Field, ErrorMessage } from 'formik';

export const InternetSpeed = () => {
    return (
        <div className="cco_form_row">
            <div className="cco_form_col cco_form_col_0">
                <h2 className="cco_form_heading">4. Test Site Capacity</h2>

                <div className="cco_form_wrap">
                    <ul className="cco_form_fieldset">
                        <li className="cco_form_fieldset_item">
                            <label htmlFor="ccoTestingStations">
                            How many candidate testing stations do you intend to have?
                            </label>

                            <Field type="text" id="ccoTestingStations" name="numberOfCandidateTestingStations" />

                            <ErrorMessage component={'div'} name={'numberOfCandidateTestingStations'} className="cco_form_message cco_form_message_error" />

                            {/* <p className="cco_form_note">
                                <strong>Note:</strong> When determining required internet speeds, take the test station capacity and add an additional device
                                for the proctor. For example, to determine the required internet download/upload speed for a test site with 10 test stations,
                                figure 10 candidates + 1 proctor = 11 devices x 1 Mbps per device = 11 Mbps total download/upload speed. It is recommended that
                                you have 12 Mbps download speed and 3 Mbps upload speed per device.
                            </p> */}
                        </li>
                        <li className="cco_form_fieldset_item">
                            <label htmlFor="ccoInternetSpeed">
                                Have you confirmed the internet download/upload speed will support your number of intended candidate testing stations, and an additional work station for the proctor?
                                <br />
                                <small>(1 Mbps per device required)</small>
                            </label>

                            <Field as="select" name="internetSpeed" id="ccoInternetSpeed">
                                <option value="default" disabled={true}>
                                    Select an option
                                </option>

                                <option value="yes">Yes</option>
                                <option value="no">No - Yes is required to submit application</option>
                            </Field>

                            <ErrorMessage component={'div'} name={'internetSpeed'} className="cco_form_message cco_form_message_error" />

                            <p className="cco_form_note">
                                Don’t know your internet speed? Contact testing facility IT support or run a speed test. This link will allow you to run a live
                                speed test:{' '}
                                <a href="https://www.speedtest.net/" target="_blank" rel="noopener noreferrer">
                                    https://www.speedtest.net/
                                </a>
                                . Make sure you perform the test in the testing room you plan to use.<br/>

                                <p style={{marginTop: '10px'}}><strong>Note:</strong> When determining required internet speeds, take the test station capacity and add an additional device
                                for the proctor. For example, to determine the required internet download/upload speed for a test site with 10 test stations,
                                figure 10 candidates + 1 proctor = 11 devices x 1 Mbps per device = 11 Mbps total download/upload speed. It is recommended that
                                you have 12 Mbps download speed and 3 Mbps upload speed per device.</p>
                                
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
