import type { IValues } from '../../../containers/NewLocation/valuesTypes';
import { allowedFileTypes } from '../../../tools/utils';

import classNames from 'classnames';

import { useFormikContext } from 'formik';
import { useState, useMemo } from 'react';

export const LocationDoorSign = ({
    handler,
    editable = true,
}: {
    handler?: (step: number) => void;
    editable?: boolean;
}) => {
    const { values, errors, touched, submitCount } = useFormikContext<IValues>();

    const [isExpanded, setIsExpanded] = useState(false);

    const isFormCompleted = useMemo(
        () => ['doorSign'].every(field => !errors[field as keyof IValues] && touched[field as keyof IValues]),
        [errors, touched]
    );

    return (
        <div
            className={classNames('cco_collapse', {
                active: isExpanded,
            })}
        >
            <div className="cco_collapse_header">
                <div className="cco_text cco_text_3">
                    <div className="cco_text_inner">
                        <h3>2. Location Door Sign</h3>

                        <div
                            className={classNames({
                                success: isFormCompleted,
                                error: !isFormCompleted,
                            })}
                        >
                            {isFormCompleted
                                ? 'Completed'
                                : submitCount < 1
                                ? 'In Progress'
                                : 'Please fill out all fields'}
                        </div>
                    </div>
                </div>

                <button
                    type="button"
                    className="cco_collapse_toggle"
                    onClick={() => setIsExpanded(prevState => !prevState)}
                >
                    <span className="cco_collapse_toggle_text">View</span>
                </button>
            </div>

            <div className="cco_collapse_body">
                <div className="cco_collapse_body_inner">
                    <div className="cco_blurb cco_blurb_2">
                        <div className="cco_blurb_img">
                            {(values.doorSign instanceof File && values.doorSign.type.includes('image')) ||
                            ('string' === typeof values.doorSign &&
                                values.doorSign &&
                                !values.doorSign.includes('.pdf')) ? (
                                <img
                                    src={
                                        values.doorSign instanceof File
                                            ? URL.createObjectURL(values.doorSign)
                                            : values.doorSign
                                    }
                                    onLoad={e => {
                                        const src = e.currentTarget.src;

                                        if (src.startsWith('blob:')) URL.revokeObjectURL(src);
                                    }}
                                    alt="Door Sign"
                                />
                            ) : (
                                <span className="cco_blurb_img_icon cco_blurb_img_icon_camera"></span>
                            )}
                        </div>

                        {values.doorSign && (
                            <div className="cco_blurb_title">
                                 {values.doorSign instanceof File && allowedFileTypes.includes(values.doorSign.type) ? values.doorSign.name : null /* values.doorSign */}
                            {(editable && !allowedFileTypes.includes(values.doorSign.type) )?(<p className='cco_blurb cco_blurb_error cco_blurb_3'>File type not supported.</p>):(null)}
                              
                              </div>
                        )}
                    </div>

                    {editable && (
                        <div className="cco_btn cco_btn_2">
                            <a
                                href="#"
                                onClick={e => {
                                    handler?.(1);
                                    e.preventDefault();
                                }}
                            >
                                Edit
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
